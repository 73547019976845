import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import aws from "aws-sdk";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
} from "@mui/material";
import NavbarMobile from "./NavbarMobile";
import { useMediaQuery } from "react-responsive";

const ACCESSKEYID = "AKIA6O3BXFU5B2H3SBPC";
const SECRETKEY =  "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";
const BUCKETNAME = "crystal-tende-bucket";

aws.config.update({ region: "eu-central-1" });
const s3 = new aws.S3({ accessKeyId: ACCESSKEYID, secretAccessKey: SECRETKEY });

var bucketParamsLavori = {
  Bucket: BUCKETNAME,
  Prefix: "lavori/",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const bucketUrl = "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/";

export default function Installazioni() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [lavori, setLavori] = useState([]);
  const [openLavoro, setOpenLavoro] = useState(false);
  const [lavoroSrc, setLavoroSrc] = useState("");

  useEffect(() => {
    s3.listObjects(bucketParamsLavori, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setLavori(keyArray);
      }
    });
  }, []);

  const handleOpenLavoro = (lavoro) => {
    setLavoroSrc(lavoro);
    setOpenLavoro(true);
  };

  return (
    <>
      <div
        className={
          isTabletOrMobile
            ? "installazioni-container-mobile"
            : "installazioni-container"
        }
      >
        {isTabletOrMobile ? <NavbarMobile /> : <Navbar />}
        <div className="installazioni-subcontainer">
          <div
            className={
              isTabletOrMobile ? "product-title-mobile" : "product-title"
            }
            style={{ margin: "-3rem 0" }}
          >
            <h3>Le nostre installazioni</h3>
          </div>
          <hr
            className={
              isTabletOrMobile ? "product-title-hr-mobile" : "product-title-hr"
            }
          ></hr>
        </div>
        <div style={{ padding: isTabletOrMobile ? "0 1.4rem" : "0" }}>
          <Grid container>
            {lavori.map((lavoro, index) => {
              return (
                <Grid
                  item
                  xs={6}
                  md={3}
                  className="center"
                  style={{
                    cursor: "pointer",
                    padding: "1rem 0",
                  }}
                  key={index}
                >
                  <img
                    src={`${bucketUrl}${lavoro}`}
                    alt={lavoro}
                    onClick={() => handleOpenLavoro(lavoro)}
                    className={
                      isTabletOrMobile
                        ? "product-img-item-mobile lavori-img"
                        : "product-img-item lavori-img"
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      <Dialog
        open={openLavoro}
        onClose={() => setOpenLavoro(false)}
        TransitionComponent={Transition}
        maxWidth="true"
      >
        <DialogContent style={{ borderRadius: "10px" }}>
          <img
            src={`${bucketUrl}${lavoroSrc}`}
            alt={lavoroSrc}
            className={
              isTabletOrMobile
                ? "lavori-img-dialog-mobile"
                : "lavori-img-dialog"
            }
          />
        </DialogContent>
        <DialogActions style={{ paddingRight: "1rem", paddingBottom: "1rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenLavoro(false)}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
