import { Grid, Typography } from "@mui/material";
import React from "react";

export default function Footer() {
  return (
    <div className="footer-container">
      <Grid container className="footer">
        <Grid item xs={2}>
          <img
            src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/logo_crystal.jpg"
            alt="logo"
            className="footer-img"
          />
        </Grid>
        <Grid item xs={6} className="footer-grid-container">
          <Typography variant="subtitle2">
            © {new Date().getFullYear()} Tutti i diritti sono riservati -
            Crystal Tende
            <br></br>
            Partita IVA e Registro Imprese di Bologna: 03784831202.
            <br></br>
            <span>Sede legale: Via Calvart</span>
            <br></br>
          </Typography>
        </Grid>
        <Grid item xs={4} className="contacts">
          <h3 style={{ margin: "0" }}>Contattaci</h3>
          <div style={{ width: "100%", display: "flex" }}>
            <span style={{ width: "50%" }}>Mail:</span>
            <span style={{ width: "50%", textAlign: "right" }}>
              {" "}
              info@crystaltende.com
            </span>
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <span style={{ width: "50%" }}>PEC: </span>
            <span style={{ width: "50%", textAlign: "right" }}>
              manuel.loscalzo@pec.it
            </span>
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <span style={{ width: "50%" }}>Cellulare: </span>
            <span style={{ width: "50%", textAlign: "right" }}>
              +39 3480552778
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
