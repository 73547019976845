import React, { useState } from "react";
import { Box, Grid, SwipeableDrawer } from "@mui/material";
import { Link } from "react-router-dom";

export default function NavbarMobile() {
  const [openMenu, setOpenMenu] = useState(false);

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <nav style={{ padding: "1rem", display: "flex" }}>
      <div style={{ width: "90%" }}>
        <Link to="/">
          <img
            src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/logo_crystal.jpg"
            alt="logo_crystal_tende"
            style={{ width: "70px", height: "50px" }}
          />
        </Link>
      </div>
      <Box style={{ zIndex: "100" }}>
        <button
          aria-label="Main Menu"
          onClick={() => setOpenMenu(!openMenu)}
          className={openMenu ? "menu opened" : "menu"}
        >
          <svg width="50" height="50" viewBox="0 0 100 100">
            <path
              className="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path className="line line2" d="M 20,50 H 80" />
            <path
              className="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </button>
      </Box>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        style={{ zIndex: "90" }}
        anchor="right"
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        onOpen={() => setOpenMenu(true)}
      >
        <Box className="nav-mobile-container">
          <Grid container style={{ height: "50%" }}>
            <Grid item xs={12} className="center">
              <div className="center drawer-box-item">
                <h1 className="drawer-items">
                  <Link to="/" className="link">
                    Home
                  </Link>
                </h1>
              </div>
            </Grid>
            <Grid item xs={12} className="center">
              <div className="center drawer-box-item">
                <h1 className="drawer-items">
                  <Link to="/prodotti" className="link">
                    Prodotti
                  </Link>
                </h1>
              </div>
            </Grid>
            <Grid item xs={12} className="center">
              <div className="center drawer-box-item">
                <h1 className="drawer-items">
                  <Link to="/agevolazioni" className="link">
                    Agevolazioni
                  </Link>
                </h1>
              </div>
            </Grid>
            <Grid item xs={12} className="center">
              <div className="center drawer-box-item">
                <h1 className="drawer-items">
                  <Link to="/installazioni" className="link">
                    Installazioni
                  </Link>
                </h1>
              </div>
            </Grid>
            <Grid item xs={12} className="center">
              <div className="center drawer-box-item">
                <h1 className="drawer-items">
                  <Link to="/contatti" className="link">
                    Contatti
                  </Link>
                </h1>
              </div>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
    </nav>
  );
}
