import React from "react";
import { Button, Grid, Typography, Card } from "@mui/material";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const images = [
  {
    label: "1",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola1.jpg",
  },
  {
    label: "2",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola2.jpg",
  },
  {
    label: "3",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola3.jpg",
  },
  {
    label: "4",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/lavori/lavoro125.jpeg",
  },
  {
    label: "5",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola5.jpg",
  },
  {
    label: "6",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola6.jpg",
  },
];
export default function Home() {
  return (
    <div className="container">
      <section className="section-one">
        <nav>
          <div className="nav-item">
            <h2 className="hvr-underline-from-center-white">
              <Link to="/" className="link">
                HOME
              </Link>
            </h2>
          </div>
          <div className="nav-item">
            <h2 className="hvr-underline-from-center-white">
              <Link to="/prodotti" className="link">
                Prodotti
              </Link>
            </h2>
          </div>
          <div className="nav-item">
            <h2 className="hvr-underline-from-center-white">
              <Link to="/agevolazioni" className="link">
                Agevolazioni
              </Link>
            </h2>
          </div>
          <div className="nav-item">
            <h2 className="hvr-underline-from-center-white">
              <Link to="/installazioni" className="link">
                Installazioni
              </Link>
            </h2>
          </div>
          <div className="nav-item">
            <h2 className="hvr-underline-from-center-white">
              <Link to="/contatti" className="link">
                Contatti
              </Link>
            </h2>
          </div>
          <Button style={{ padding: "0" }}>
            <Link to="/" className="link">
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/logo_crystal.jpg"
                alt="logo"
                style={{ width: "100px", height: "80px" }}
              />
            </Link>
          </Button>
        </nav>
        <div className="center" style={{ width: "100%", marginTop: "1rem" }}>
          <Card
            variant="outlined"
            className="card"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <h1 className="ecobonus">
              Grazie all'{" "}
              <span style={{ color: "lightgreen" }}> ECOBONUS </span>
              statale puoi recuperare il
              <span style={{ color: "lightgreen" }}> 50% </span>
              della spesa!
            </h1>
            <h1 className="showroom">Showroom in allestimento</h1>
          </Card>
        </div>
      </section>
      <section className="section-three">
        <h1 className="section-three-title">Esperienza pluridecennale</h1>
        <Grid container style={{ padding: "3rem 5rem" }}>
          <Grid
            item
            xs={6}
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <div className="section-three-img">
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/tenda1.jpg"
                alt="tenda1"
                style={{
                  width: "500px",
                  height: "375px",
                  transform: "translateX(15px) translateY(15px)",
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            className="center"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            style={{ paddingLeft: "3rem" }}
          >
            <h3 className="description">
              Offriamo un servizio che parte dal sopralluogo al punto di
              installazione con particolare attenzione alle richieste del
              cliente.
            </h3>
          </Grid>
          <Grid
            item
            xs={6}
            className="center"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            style={{ paddingRight: "2rem" }}
          >
            <h3 className="description">
              Garantiamo assistenza anche nel post-installazione trovando sempre
              il modo di soddisfare al meglio le esigenze in termini di estetica
              e funzionalit&agrave;.
            </h3>
          </Grid>

          <Grid
            item
            xs={6}
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <div className="section-three-img-two">
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/tenda2.jpg"
                alt="tenda2"
                style={{
                  width: "500px",
                  height: "375px",
                  transform: "translateX(-15px) translateY(15px)",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <hr className="hr"></hr>
          </Grid>
          <Grid item xs={12}>
            <div className="center" style={{ textAlign: "center" }}>
              <h3 style={{ fontSize: "2.5rem" }} data-aos="fade-up">
                Portiamo al cliente il massimo della professionalit&agrave; e
                della qualit&agrave;, accompagnata da una vasta gamma di
                prodotti personalizzabili per ogni situazione.
              </h3>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Splide options={{ autoplay: "true" }}>
              {images.map((image) => {
                return (
                  <SplideSlide className="center" key={image.label}>
                    <img
                      src={image.imgPath}
                      alt={image.label}
                      style={{
                        height: "565px",
                        width: "800px",
                        border: "2px solid black",
                      }}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>
            <div className="center">
              <h2>
                <Link to="/installazioni" style={{ color: "black" }}>
                  Guarda altri lavori
                </Link>
              </h2>
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", padding: "4rem 0" }}>
            <h2 style={{ fontSize: "2.5rem" }}>Code</h2>
            <Link to="/prodotti/code" className="link">
              <div className="center">
                <img
                  src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/code-home-page.jpeg"
                  alt="code-home-page"
                  height="565px"
                  width="800px"
                  style={{ border: "2px solid black" }}
                />
              </div>
            </Link>
          </Grid>
        </Grid>
      </section>
      <section className="section-two">
        <div className="center">
          <h1 className="section-two-header">
            <b>Perch&egrave; sceglierci</b>
          </h1>
        </div>
        <Grid container style={{ padding: "0 6rem" }}>
          <Grid item xs={6} style={{ padding: "0 8rem" }}>
            <h2 className="title-item">
              {" "}
              <b>
                Assicurazione <br></br> 10 anni su
                <br></br>responsabilit&agrave; civile
              </b>
            </h2>
            <div style={{ margin: "2rem 0" }}></div>
            <Typography variant="subtitle1" className="subtitle-item">
              Si tratta di un'assicurazione per danni causati a cose o persone
              derivanti da ben accertate cause relative a difetti del prodotto
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "4rem",
              }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/insurance.png"
                alt="insurance"
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ padding: "20rem 8rem 0 8rem" }}>
            <h2 className="title-item" style={{ textAlign: "right" }}>
              {" "}
              <b>100% Made in Italy</b>
            </h2>
            <div style={{ margin: "2rem 0" }}></div>
            <Typography
              variant="subtitle1"
              className="subtitle-item"
              style={{ textAlign: "right" }}
            >
              I prodotti sono esclusivamente <br></br> Made in Italy,
              espressione di qualità, stile e prestigio
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "4rem",
              }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/italia.jpg"
                alt="italia"
                style={{ width: "300px", height: "180px" }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ padding: "5rem 8rem" }}>
            <h2 className="title-item">
              {" "}
              <b>Marchi CE</b>
            </h2>
            <div style={{ margin: "2rem 0" }}></div>
            <Typography variant="subtitle1" className="subtitle-item">
              I prodotti vengono progettati e realizzati nel rispetto della
              Normativa UNI EN 13561 con CE
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "4rem",
              }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/ce.jpg"
                alt="CE"
                style={{ width: "300px", height: "200px" }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ padding: "20rem 8rem 0 8rem" }}>
            <h2 className="title-item" style={{ textAlign: "right" }}>
              {" "}
              <b>Resistenza al vento, sole e pioggia</b>
            </h2>
            <div style={{ margin: "2rem 0" }}></div>
            <Typography
              variant="subtitle1"
              className="subtitle-item"
              style={{ textAlign: "right" }}
            >
              I prodotti sono di grande resistenza meccanica alle sollecitazioni
              del vento e specifici per la protezione dal sole e dalla pioggia
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "4rem",
              }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/windy.png"
                alt="windy"
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          </Grid>
        </Grid>
        <div className="aiuti-stato-container">
          <h1>
            La ditta ha ricevuto nell'anno 2020 benefici rientranti nel regime
            degli aiuti di Stato o regime de minimis per i quali sussiste
            l'obbligo di pubblicazione nel Registro Nazionale degli aiuti di
            Stato di cui all'art. 52 della L. 234/2012.
          </h1>
        </div>
      </section>
    </div>
  );
}
