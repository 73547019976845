import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import aws from "aws-sdk";
import data from "../../data";
import ProdottoDetailDialog from "./ProdottoDetailDialog";
import ProdottoItem from "./ProdottoItem";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../NavbarMobile";

const ACCESSKEYID = "AKIA6O3BXFU5B2H3SBPC";
const SECRETKEY =  "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";
const BUCKETNAME = "crystal-tende-bucket";

aws.config.update({ region: "eu-central-1" });
const s3 = new aws.S3({ accessKeyId: ACCESSKEYID, secretAccessKey: SECRETKEY });

var bucketParamsAutoportanti = {
  Bucket: BUCKETNAME,
  Prefix: "pergolati/autoportanti/",
};
var bucketParamsPergozip = {
  Bucket: BUCKETNAME,
  Prefix: "pergolati/pergozip/",
};
var bucketParamsPiantaneAnteriori = {
  Bucket: BUCKETNAME,
  Prefix: "pergolati/piantaneAnteriori/",
};
var bucketParamSenzaPiantane = {
  Bucket: BUCKETNAME,
  Prefix: "pergolati/senzaPiantane/",
};
var bucketParamsSolarium = {
  Bucket: BUCKETNAME,
  Prefix: "pergolati/solarium/",
};
var bucketParamsVele = {
  Bucket: BUCKETNAME,
  Prefix: "pergolati/vele/",
};

export default function Pergolati() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [piantaneAnteriori, setPiantaneAnteriori] = useState([]);
  const [autoPortanti, setAutoPortanti] = useState([]);
  const [senzaPiantane, setSenzaPiantane] = useState([]);
  const [solarium, setSolarium] = useState([]);
  const [pergozip, setPergozip] = useState([]);
  const [vele, setVele] = useState([]);

  const [piantaneAnterioriItems, setPiantaneAnterioriItem] = useState("");
  const [autoPortantiItems, setAutoPortantiItem] = useState("");
  const [senzaPiantaneItems, setSenzaPiantaneItem] = useState("");
  const [solariumItems, setSolariumItem] = useState("");
  const [pergozipItems, setPergozipItem] = useState("");
  const [veleItems, setVeleItem] = useState("");

  const [openDetailPiantaneAnteriori, setOpenDetailPiantaneAnteriori] =
    useState(false);
  const [openDetailAutoPortanti, setOpenDetailAutoPortanti] = useState(false);
  const [openDetailSenzaPiantane, setOpenDetailSenzaPiantane] = useState(false);
  const [openDetailSolarium, setOpenDetailSolarium] = useState(false);
  const [openDetailPergozip, setOpenDetailPergozip] = useState(false);
  const [openDetailVele, setOpenDetailVele] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    s3.listObjects(bucketParamsAutoportanti, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setAutoPortanti(keyArray);
      }
    });

    s3.listObjects(bucketParamsPiantaneAnteriori, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setPiantaneAnteriori(keyArray);
      }
    });

    s3.listObjects(bucketParamsPergozip, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setPergozip(keyArray);
      }
    });

    s3.listObjects(bucketParamSenzaPiantane, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setSenzaPiantane(keyArray);
      }
    });

    s3.listObjects(bucketParamsSolarium, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setSolarium(keyArray);
      }
    });

    s3.listObjects(bucketParamsVele, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setVele(keyArray);
      }
    });
  }, []);

  const handlePiantaneAnterioriDetail = (name, img) => {
    var description;
    for (var i = 0; i < data.pergolati[0].piantaneAnteriori.length; i++) {
      if (data.pergolati[0].piantaneAnteriori[i] === name) {
        description = data.pergolati[0].descrizionepiantaneAnteriori[i];
      }
    }
    setPiantaneAnterioriItem({
      img,
      name: name.replaceAll("_", " "),
      description,
    });
    setOpenDetailPiantaneAnteriori(true);
  };

  const handleAutoPortantiDetail = (name, img) => {
    var description;
    for (var i = 0; i < data.pergolati[0].pianeEAutoportanti.length; i++) {
      if (data.pergolati[0].pianeEAutoportanti[i] === name) {
        description = data.pergolati[0].descrizionepianeEAutoportanti[i];
      }
    }
    setAutoPortantiItem({
      img,
      name: name.replaceAll("_", " "),
      description,
    });
    setOpenDetailAutoPortanti(true);
  };

  const handleSenzaPiantaneDetail = (name, img) => {
    var description;
    for (var i = 0; i < data.pergolati[0].senzaPiantane.length; i++) {
      if (data.pergolati[0].senzaPiantane[i] === name) {
        description = data.pergolati[0].descrizionesenzaPiantane[i];
      }
    }
    setSenzaPiantaneItem({
      img,
      name: name.replaceAll("_", " "),
      description,
    });
    setOpenDetailSenzaPiantane(true);
  };

  const handleSolariumDetail = (name, img) => {
    var description;
    for (var i = 0; i < data.pergolati[0].solarium.length; i++) {
      if (data.pergolati[0].solarium[i] === name) {
        description = data.pergolati[0].descrizionesolarium[i];
      }
    }
    setSolariumItem({
      img,
      name: name.replaceAll("_", " "),
      description,
    });
    setOpenDetailSolarium(true);
  };

  const handlePergozipDetail = (name, img) => {
    var description;
    for (var i = 0; i < data.pergolati[0].pergozip.length; i++) {
      if (data.pergolati[0].pergozip[i] === name) {
        description = data.pergolati[0].descrizionepergozip[i];
      }
    }
    setPergozipItem({
      img,
      name: name.replaceAll("_", " "),
      description,
    });
    setOpenDetailPergozip(true);
  };

  const handleVeleDetail = (name, img) => {
    var description;
    for (var i = 0; i < data.pergolati[0].vele.length; i++) {
      if (data.pergolati[0].vele[i] === name) {
        description = data.pergolati[0].descrizionevele[i];
      }
    }
    setVeleItem({
      img,
      name: name.replaceAll("_", " "),
      description,
    });
    setOpenDetailVele(true);
  };

  return (
    <div style={{ height: "100%", padding: "1rem 1rem 5rem 1rem" }}>
      {isTabletOrMobile ? <NavbarMobile /> : <Navbar />}

      <div className="center" style={{ width: "100%", marginTop: "2rem" }}>
        <h1 className="product-title">Pergolati</h1>
      </div>
      <hr
        className={
          isTabletOrMobile ? "product-title-hr-mobile" : "product-title-hr"
        }
      ></hr>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Piantane anteriori</h2>
      </div>
      <ProdottoItem
        prodotto={piantaneAnteriori}
        handleDetail={handlePiantaneAnterioriDetail}
      />

      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Piane e autoportanti</h2>
      </div>
      <ProdottoItem
        prodotto={autoPortanti}
        handleDetail={handleAutoPortantiDetail}
      />

      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Senza piantane</h2>
      </div>
      <ProdottoItem
        prodotto={senzaPiantane}
        handleDetail={handleSenzaPiantaneDetail}
      />

      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Solarium</h2>
      </div>
      <ProdottoItem prodotto={solarium} handleDetail={handleSolariumDetail} />

      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Pergozip</h2>
      </div>
      <ProdottoItem prodotto={pergozip} handleDetail={handlePergozipDetail} />

      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Vele</h2>
      </div>

      <ProdottoItem prodotto={vele} handleDetail={handleVeleDetail} />

      <ProdottoDetailDialog
        open={openDetailPiantaneAnteriori}
        setOpen={setOpenDetailPiantaneAnteriori}
        prodotto={piantaneAnterioriItems}
      />

      <ProdottoDetailDialog
        open={openDetailAutoPortanti}
        setOpen={setOpenDetailAutoPortanti}
        prodotto={autoPortantiItems}
      />

      <ProdottoDetailDialog
        open={openDetailSenzaPiantane}
        setOpen={setOpenDetailSenzaPiantane}
        prodotto={senzaPiantaneItems}
      />

      <ProdottoDetailDialog
        open={openDetailSolarium}
        setOpen={setOpenDetailSolarium}
        prodotto={solariumItems}
      />

      <ProdottoDetailDialog
        open={openDetailPergozip}
        setOpen={setOpenDetailPergozip}
        prodotto={pergozipItems}
      />

      <ProdottoDetailDialog
        open={openDetailVele}
        setOpen={setOpenDetailVele}
        prodotto={veleItems}
      />
    </div>
  );
}
