import React, { useState, useEffect } from "react";
import { Grid, Card } from "@mui/material";
import { Link } from "react-router-dom";
import NavbarMobile from "./NavbarMobile";

export default function ProdottiMobile() {
  const [imgTenda, setImgTenda] = useState("");
  const [imgPergolato, setImgPergolato] = useState("");
  const [imgBioclimatico, setImgBioclimatico] = useState("");
  const [imgChiusura, setImgChiusura] = useState("");
  const [imgZanzariere, setImgZanzariere] = useState("");
  const [imgPavimento, setImgPavimento] = useState("");
  const [imgFrangisole, setImgFrangisole] = useState("");
  const [imgVele, setImgVele] = useState("");
  const [imgCode, setImgCode] = useState("");

  useEffect(() => {
    setImgTenda(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/lavori/lavoro98.jpeg"
    );
    setImgPergolato(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/lavori/lavoro104.jpeg"
    );
    setImgBioclimatico(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola5.jpg"
    );
    setImgChiusura(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/chiusurePerimetrali.jpg"
    );
    setImgZanzariere(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/zanzariere1.jpg"
    );
    setImgPavimento(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pavimenti.jpg"
    );
    setImgFrangisole(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/frangisole.jpg"
    );
    setImgVele(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/vele.jpg"
    );
    setImgCode(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/code.jpg"
    );
  }, []);
  return (
    <div className="product-container-mobile">
      <NavbarMobile />
      <div style={{ width: "100%", marginTop: "2rem" }} className="center">
        <h1 className="product-title-mobile">I nostri prodotti</h1>
      </div>
      <hr className="product-title-hr-mobile"></hr>
      <Grid container style={{ padding: "3rem 2rem" }}>
        <Grid item xs={12}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <Link
              to="/prodotti/tendeDaEsterno"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgTenda}
                  alt="tenda"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center">
                <h1 style={{ marginBottom: "0" }}>Tende da esterno</h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>
                  {" "}
                  Tende a bracci, pensiline, tende a cadute, strutture fisse,
                  tunnel, capottine, gazebo.
                </h3>
              </div>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "4rem" }}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <Link
              to="/prodotti/pergolati"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgPergolato}
                  alt="pergolato"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center">
                <h1 style={{ marginBottom: "0" }}>Pergolati</h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>
                  In alluminio o legno, con la possibilit&agrave; di
                  personalizzazioni per creare nuovi ed eleganti spazi per
                  vivere
                </h3>
              </div>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "4rem" }}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <Link
              to="/prodotti/bioclimatiche"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgBioclimatico}
                  alt="bioclimatica"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center">
                <h1 style={{ marginBottom: "0" }}>Bioclimatiche</h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>
                  Bioclimatiche con lamelle orientabili e riscaldate.
                </h3>
              </div>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "4rem" }}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <Link
              to="/prodotti/chiusure"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgChiusura}
                  alt="chiusura"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center">
                <h1 style={{ marginBottom: "0" }}>Chiusure perimetrali</h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>Tende verticali</h3>
              </div>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "4rem" }}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <a
              href="https://effetrade.it/"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgZanzariere}
                  alt="zanzariere"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center">
                <h1 style={{ marginBottom: "0" }}>Zanzariere e tapparelle</h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>
                  Tende a bracci, pensiline, tende a cadute, strutture fisse,
                  tunnel, cappottine, gazebo
                </h3>
              </div>
            </a>
          </Card>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "4rem" }}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <Link
              to="/prodotti/pavimenti"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgPavimento}
                  alt="pavimento"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center" style={{ textAlign: "center" }}>
                <h1 style={{ marginBottom: "0" }}>
                  Pavimenti per interno ed esterno
                </h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>
                  Tende a bracci, pensiline, tende a cadute, strutture fisse,
                  tunnel, cappottine, gazebo{" "}
                </h3>
              </div>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "4rem" }}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <Link
              to="/prodotti/frangisole"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgFrangisole}
                  alt="frangisole"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center" style={{ textAlign: "center" }}>
                <h1 style={{ marginBottom: "0" }}>Frangisole</h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>
                  Soluzioni per arredare gli spazi esterni con eleganza e
                  leggerezza
                </h3>
              </div>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "4rem" }}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <Link
              to="/prodotti/vele"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgVele}
                  alt="vele"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center" style={{ textAlign: "center" }}>
                <h1 style={{ marginBottom: "0" }}>Vele</h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>
                  Soluzioni per arredare gli spazi esterni con eleganza e
                  leggerezza
                </h3>
              </div>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "4rem" }}>
          <Card elevation={3} style={{ borderRadius: "20px" }}>
            <Link
              to="/prodotti/code"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <img
                  src={imgCode}
                  alt="code"
                  style={{ width: "100%", height: "300px" }}
                  className="product-img"
                />
              </div>
              <div className="center" style={{ textAlign: "center" }}>
                <h1 style={{ marginBottom: "0" }}>Code</h1>
              </div>
              <div
                className="center"
                style={{ textAlign: "center", padding: "0 1rem" }}
              >
                <h3 style={{ marginTop: "0" }}>
                  L’innovativo e rivoluzionario sistema a doghe in alluminio,
                  che si integra perfettamente a diverse soluzioni
                </h3>
              </div>
            </Link>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
