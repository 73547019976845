import React, { useState, useEffect } from "react";
import aws from "aws-sdk";
import data from "../../data";
import ProdottoDetailDialog from "./ProdottoDetailDialog";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../NavbarMobile";
import Navbar from "../Navbar";
import { Grid } from "@mui/material";

const ACCESSKEYID = "AKIA6O3BXFU5B2H3SBPC";
const SECRETKEY =  "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";
const BUCKETNAME = "crystal-tende-bucket";

aws.config.update({ region: "eu-central-1" });
const s3 = new aws.S3({ accessKeyId: ACCESSKEYID, secretAccessKey: SECRETKEY });
var bucketVele = {
  Bucket: BUCKETNAME,
  Prefix: "vele",
};

const bucketUrl = "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/";

export default function Vele() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [vele, setVele] = useState([]);

  const [veleItem, setVeleItem] = useState("");

  const [openDetailVele, setOpenDetailVele] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    s3.listObjects(bucketVele, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 2);
        setVele(keyArray);
      }
    });
  }, []);

  const handleDetailVele = (name, img) => {
    data.vele.forEach((element) => {
      if (element.nome === name) {
        setVeleItem({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailVele(true);
  };

  return (
    <div
      style={{
        height: "100vh",
        padding: "1rem 1rem 5rem 1rem",
      }}
    >
      {isTabletOrMobile ? <NavbarMobile /> : <Navbar />}
      <div className="center" style={{ width: "100%", marginTop: "2rem" }}>
        <h1 className="product-title">Vele</h1>
      </div>
      <hr
        className={
          isTabletOrMobile ? "product-title-hr-mobile" : "product-title-hr"
        }
      ></hr>

      <Grid container spacing={3} style={{ marginTop: "3rem" }}>
        {vele.map((element, index) => {
          return (
            <Grid
              item
              xs={6}
              md={3}
              className="center"
              style={{ cursor: "pointer" }}
              key={index}
            >
              <div
                onClick={() =>
                  handleDetailVele(
                    element.split("/")[1].split(".")[0],
                    `${bucketUrl}${element}`
                  )
                }
              >
                <img
                  src={`${bucketUrl}${element}`}
                  alt={element.name}
                  className={
                    isTabletOrMobile
                      ? "product-img-item-mobile"
                      : "product-img-item"
                  }
                />
                <div className="center">
                  <h3 style={{ textTransform: "uppercase" }}>
                    {element.split("/")[1].split(".")[0].replaceAll("_", " ")}
                  </h3>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <ProdottoDetailDialog
        open={openDetailVele}
        setOpen={setOpenDetailVele}
        prodotto={veleItem}
      />
    </div>
  );
}
