import React, { useState, useEffect } from "react";
import aws from "aws-sdk";
import DownloadIcon from "@mui/icons-material/Download";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../NavbarMobile";
import Navbar from "../Navbar";
import { Grid, Button } from "@mui/material";

const ACCESSKEYID = "AKIA6O3BXFU5B2H3SBPC";
const SECRETKEY =  "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";
const BUCKETNAME = "crystal-tende-bucket";
const bucketUrl = "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/";

aws.config.update({ region: "eu-central-1" });
const s3 = new aws.S3({ accessKeyId: ACCESSKEYID, secretAccessKey: SECRETKEY });

var bucketCode = {
  Bucket: BUCKETNAME,
  Prefix: "code",
};

export default function Code() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [code, setCode] = useState([]);

  useEffect(() => {
    s3.listObjects(bucketCode, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 3);
        setCode(keyArray);
      }
    });
  }, []);

  return (
    <div
      style={{
        height: "100%",
        padding: "1rem 1rem 5rem 1rem",
      }}
    >
      {isTabletOrMobile ? <NavbarMobile /> : <Navbar />}
      <div className="center" style={{ width: "100%", marginTop: "2rem" }}>
        <h1 className="product-title">Code</h1>
      </div>
      <hr
        className={
          isTabletOrMobile ? "product-title-hr-mobile" : "product-title-hr"
        }
      ></hr>

      <Grid container spacing={3} style={{ marginTop: "1rem" }}>
        {code.map((element, index) => {
          return (
            <Grid item xs={12} style={{ padding: "3rem 0" }} key={index}>
              <div className="center">
                <img
                  src={`${bucketUrl}${element}`}
                  alt={element.name}
                  className={
                    isTabletOrMobile
                      ? "product-img-item-mobile"
                      : "product-img-item"
                  }
                  style={{
                    width: isTabletOrMobile ? "350px" : "500px",
                    height: isTabletOrMobile ? "150px" : "224px",
                    aspectRatio: "250/147",
                  }}
                />
              </div>
              <div className="center">
                <p
                  style={{
                    fontSize: isTabletOrMobile ? "1.2rem" : "2.5rem",
                    width: "60%",
                  }}
                >
                  L’innovativo e rivoluzionario sistema a doghe in alluminio,
                  che si integra perfettamente a diverse soluzioni di pergolati,
                  subentrando all’elemento piantana.
                  <br></br> <b>CODE </b>
                  rappresenta l’emblema del <b>custom-made</b>: ogni progetto
                  outdoor diventa unico ed esclusivo. Possibilità di integrare
                  <b> CODE </b>
                  con chiusure perimetrali tuttovetro, tende verticali a cavo, o
                  drappeggi.
                </p>
              </div>
            </Grid>
          );
        })}
        <Grid item xs={12} className="center">
        <div style={{ padding: "0 0 2rem 0" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#61CE70", padding: "1.2rem" }}
          >
            <div className="center">
              <DownloadIcon style={{ marginRight: "0.5rem" }} />
              <a
                href="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/Code.pdf"
                download
                style={{ textDecoration: "none", color: "white" }}
              >
                Consulta il PDF sulle CODE
              </a>
            </div>
          </Button>
        </div>
        </Grid>
      </Grid>
    </div>
  );
}
