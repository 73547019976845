import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import aws from "aws-sdk";
import data from "../../data";
import ProdottoDetailDialog from "./ProdottoDetailDialog";
import ProdottoItem from "./ProdottoItem";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../NavbarMobile";
import { Grid } from "@mui/material";

const bucketUrl = "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/";

const ACCESSKEYID = "AKIA6O3BXFU5B2H3SBPC";
const SECRETKEY =  "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";
const BUCKETNAME = "crystal-tende-bucket";

aws.config.update({ region: "eu-central-1" });
const s3 = new aws.S3({ accessKeyId: ACCESSKEYID, secretAccessKey: SECRETKEY });

const bucketParamsTendeACaduta = {
  Bucket: BUCKETNAME,
  Prefix: "chiusurePerimetrali/tendeACaduta",
};

const bucketParamsPergosky = {
  Bucket: BUCKETNAME,
  Prefix: "chiusurePerimetrali/pergosky",
};

export default function Chiusure() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [tendeACaduta, setTendeACaduta] = useState([]);
  const [tendeACadutaItem, setTendeACadutaItem] = useState("");
  const [openDetailTendeACaduta, setOpenDetailTendeACaduta] = useState(false);

  const [pergosky, setPergosky] = useState([]);
  const [pergoskyItem, setPergoskyItem] = useState("");
  const [openDetailPergosky, setOpenDetailPergosky] = useState(false);

  useEffect(() => {
    s3.listObjects(bucketParamsTendeACaduta, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setTendeACaduta(keyArray);
      }
    });

    s3.listObjects(bucketParamsPergosky, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setPergosky(keyArray);
      }
    });
  }, []);

  const handleTendeACadutaDetail = (name, img) => {
    data.chiusurePerimetrali[0].tendeACaduta.forEach((element) => {
      if (element.nome === name) {
        setTendeACadutaItem({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailTendeACaduta(true);
  };

  const handlePergoskyDetail = (name, img) => {
    data.chiusurePerimetrali[0].pergosky.forEach((element) => {
      if (element.nome === name) {
        setPergoskyItem({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailPergosky(true);
  };

  return (
    <div style={{ height: "100%", padding: "1rem 1rem 5rem 1rem" }}>
      {isTabletOrMobile ? <NavbarMobile /> : <Navbar />}
      <div
        className="center"
        style={{ width: "100%", marginTop: "2rem", textAlign: "center" }}
      >
        <h1 className="product-title">Chiusure perimetrali</h1>
      </div>
      <hr
        className={
          isTabletOrMobile ? "product-title-hr-mobile" : "product-title-hr"
        }
      ></hr>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        {" "}
        <h2>Pergosky</h2>
      </div>

      <Grid container spacing={3} style={{ marginTop: "1rem" }}>
        {pergosky.map((element, index) => {
          return (
            <Grid
              item
              xs={6}
              md={3}
              className="center"
              style={{ cursor: "pointer" }}
              key={index}
            >
              <div
                onClick={() =>
                  handlePergoskyDetail(
                    element.split("/")[2].split(".")[0],
                    `${bucketUrl}${element}`
                  )
                }
              >
                <img
                  src={`${bucketUrl}${element}`}
                  alt={element.name}
                  className={
                    isTabletOrMobile
                      ? "product-img-item-mobile"
                      : "product-img-item-pergosky"
                  }
                  style={{ aspectRatio: "16/9" }}
                />
                <div className="center">
                  <h3 style={{ textTransform: "uppercase" }}>
                    {element.split("/")[2].split(".")[0].replaceAll("_", " ")}
                  </h3>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <ProdottoDetailDialog
        open={openDetailPergosky}
        setOpen={setOpenDetailPergosky}
        prodotto={pergoskyItem}
        pergo={true}
      />

      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        {" "}
        <h2>Tende a caduta</h2>
      </div>
      <ProdottoItem
        prodotto={tendeACaduta}
        handleDetail={handleTendeACadutaDetail}
      />

      <ProdottoDetailDialog
        open={openDetailTendeACaduta}
        setOpen={setOpenDetailTendeACaduta}
        prodotto={tendeACadutaItem}
      />
    </div>
  );
}
