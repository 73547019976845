import React from "react";
import { Dialog, DialogTitle, DialogContent, Slide, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProdottoDetailDialog({
  open,
  setOpen,
  prodotto,
  vertical,
  pergo,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="true"
    >
      <DialogTitle>
        <Grid container spacing={3}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8} style={{ textAlign: "center" }}>
            <h1 className="dialog-item-title">{prodotto.name}</h1>
          </Grid>
          <Grid item xs={2} className="close-dialog-icon">
            <CloseIcon
              fontSize="large"
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: "2rem 3rem" }}>
        <div className="center">
          <img
            src={prodotto.img}
            alt={prodotto.name}
            style={{
              width: "300px",
              height: vertical ? "500px" : pergo ? "200px" : "300px",
            }}
          />
        </div>
        <div className="center">
          <span style={{ margin: "1rem 0 0 0", fontSize: "1.2rem" }}>
            {parse(`${prodotto.description}`)}
          </span>
        </div>
      </DialogContent>
    </Dialog>
  );
}
