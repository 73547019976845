import React, { useState, useEffect } from "react";
import { Grid, Card, Slide, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";

export default function Prodotti() {
  const [hoveredTende, setHoveredTende] = useState(false);
  const [hoveredPergolati, setHoveredPergolati] = useState(false);
  const [hoveredBioclimatiche, setHoveredBioclimatiche] = useState(false);
  const [hoveredChiusure, setHoveredChiusure] = useState(false);
  const [hoveredZanzariere, setHoveredZanzariere] = useState(false);
  const [hoveredPavimenti, setHoveredPavimenti] = useState(false);
  const [hoveredFrangisole, setHoveredFrangisole] = useState(false);
  const [hoveredVele, setHoveredVele] = useState(false);
  const [hoveredCode, setHoveredCode] = useState(false);
  const [imgTenda, setImgTenda] = useState("");
  const [imgPergolato, setImgPergolato] = useState("");
  const [imgBioclimatico, setImgBioclimatico] = useState("");
  const [imgChiusura, setImgChiusura] = useState("");
  const [imgZanzariere, setImgZanzariere] = useState("");
  const [imgPavimento, setImgPavimento] = useState("");
  const [imgFrangisole, setImgFrangisole] = useState("");
  const [imgVele, setImgVele] = useState("");
  const [imgCode, setImgCode] = useState("");

  useEffect(() => {
    setImgTenda(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/lavori/lavoro98.jpeg"
    );
    setImgPergolato(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/lavori/lavoro104.jpeg"
    );
    setImgBioclimatico(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola5.jpg"
    );
    setImgChiusura(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/chiusurePerimetrali.jpg"
    );
    setImgZanzariere(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/zanzariere1.jpg"
    );
    setImgPavimento(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pavimenti.jpg"
    );
    setImgFrangisole(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/frangisole.jpg"
    );
    setImgVele(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/vele.jpg"
    );
    setImgCode(
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/code.jpg"
    );
  }, []);

  return (
    <div className="product-container">
      <Navbar />
      <div style={{ width: "100%", marginTop: "2rem" }} className="center">
        <h1 className="product-title">I nostri prodotti</h1>
      </div>
      <hr className="product-title-hr"></hr>
      <Grid container spacing={3} style={{ marginTop: "2rem" }}>
        <Grid item xs={4} className="center">
          <Card
            elevation={3}
            style={{
              borderRadius: !hoveredTende ? "30%" : "0",
              transition: "all 0.5s",
            }}
            className="product-card"
            onMouseEnter={() => setHoveredTende(true)}
            onMouseLeave={() => setHoveredTende(false)}
          >
            <Link
              to="/prodotti/tendeDaEsterno"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgTenda ? (
                <img src={imgTenda} alt="prodotto" className="product-img" />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div style={{ padding: "0 1rem" }}>
                {hoveredTende ? (
                  <Slide
                    direction="up"
                    in={hoveredTende}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h2>
                      Tende a bracci, pensiline, tende a cadute, strutture
                      fisse, tunnel, capottine, gazebo.
                    </h2>
                  </Slide>
                ) : (
                  <h1>Tende da esterno</h1>
                )}
              </div>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={4} className="center">
          <Card
            style={{
              borderRadius: !hoveredPergolati ? "30%" : "0",
              transition: "all 0.5s",
            }}
            elevation={3}
            className="product-card"
            onMouseEnter={() => setHoveredPergolati(true)}
            onMouseLeave={() => setHoveredPergolati(false)}
          >
            <Link
              to="/prodotti/pergolati"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgPergolato ? (
                <img
                  src={imgPergolato}
                  alt="prodotto"
                  className="product-img"
                />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div style={{ padding: "0 1rem" }}>
                {hoveredPergolati ? (
                  <Slide
                    direction="up"
                    in={hoveredPergolati}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h3>
                      In alluminio o legno, con la possibilit&agrave; di
                      personalizzazioni per creare nuovi ed eleganti spazi per
                      vivere
                    </h3>
                  </Slide>
                ) : (
                  <h1>Pergolati</h1>
                )}
              </div>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            style={{
              borderRadius: !hoveredBioclimatiche ? "30%" : "0",
              transition: "all 0.5s",
            }}
            elevation={3}
            className="product-card"
            onMouseEnter={() => setHoveredBioclimatiche(true)}
            onMouseLeave={() => setHoveredBioclimatiche(false)}
          >
            <Link
              to="/prodotti/bioclimatiche"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgBioclimatico ? (
                <img
                  src={imgBioclimatico}
                  alt="prodotto"
                  className="product-img"
                />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div
                style={{
                  padding: "0 1rem",
                }}
              >
                {hoveredBioclimatiche ? (
                  <Slide
                    direction="up"
                    in={hoveredBioclimatiche}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h2 style={{ marginTop: "2rem" }}>
                      Bioclimatiche con lamelle orientabili e riscaldate.
                    </h2>
                  </Slide>
                ) : (
                  <h1>Bioclimatiche</h1>
                )}
              </div>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={4} className="center" style={{ paddingTop: "4rem" }}>
          <Card
            style={{
              borderRadius: !hoveredChiusure ? "30%" : "0",
              transition: "all 0.5s",
            }}
            elevation={3}
            className="product-card"
            onMouseEnter={() => setHoveredChiusure(true)}
            onMouseLeave={() => setHoveredChiusure(false)}
          >
            <Link
              to="/prodotti/chiusure"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgChiusura ? (
                <img src={imgChiusura} alt="prodotto" className="product-img" />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div style={{ padding: "0 1rem" }}>
                {hoveredChiusure ? (
                  <Slide
                    direction="up"
                    in={hoveredChiusure}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h2>Tende verticali</h2>
                  </Slide>
                ) : (
                  <h1>Chiusure perimetrali</h1>
                )}
              </div>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={4} className="center" style={{ paddingTop: "4rem" }}>
          <Card
            style={{
              borderRadius: !hoveredZanzariere ? "30%" : "0",
              transition: "all 0.5s",
            }}
            elevation={3}
            className="product-card"
            onMouseEnter={() => setHoveredZanzariere(true)}
            onMouseLeave={() => setHoveredZanzariere(false)}
          >
            <a
              href="https://suncover.com/"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgZanzariere ? (
                <img
                  src={imgZanzariere}
                  alt="prodotto"
                  className="product-img"
                />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div style={{ padding: "0 1rem" }}>
                {hoveredZanzariere ? (
                  <Slide
                    direction="up"
                    in={hoveredZanzariere}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h2 style={{ marginTop: "1rem" }}>
                      Tende a bracci, pensiline, tende a cadute, strutture
                      fisse, tunnel, cappottine, gazebo
                    </h2>
                  </Slide>
                ) : (
                  <h1>Zanzariere e tapparelle</h1>
                )}
              </div>
            </a>
          </Card>
        </Grid>
        <Grid item xs={4} className="center" style={{ paddingTop: "4rem" }}>
          <Card
            style={{
              borderRadius: !hoveredPavimenti ? "30%" : "0",
              transition: "all 0.5s",
            }}
            elevation={3}
            className="product-card"
            onMouseEnter={() => setHoveredPavimenti(true)}
            onMouseLeave={() => setHoveredPavimenti(false)}
          >
            <Link
              to="/prodotti/pavimenti"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgPavimento ? (
                <img
                  src={imgPavimento}
                  alt="prodotto"
                  className="product-img"
                />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div
                style={{
                  padding: "0 1rem",
                }}
              >
                {hoveredPavimenti ? (
                  <Slide
                    direction="up"
                    in={hoveredPavimenti}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h2 style={{ marginTop: "1rem" }}>
                      Tende a bracci, pensiline, tende a cadute, strutture
                      fisse, tunnel, cappottine, gazebo{" "}
                    </h2>
                  </Slide>
                ) : (
                  <h1>Pavimenti </h1>
                )}
              </div>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={4} className="center" style={{ paddingTop: "4rem" }}>
          <Card
            style={{
              borderRadius: !hoveredFrangisole ? "30%" : "0",
              transition: "all 0.5s",
            }}
            elevation={3}
            className="product-card"
            onMouseEnter={() => setHoveredFrangisole(true)}
            onMouseLeave={() => setHoveredFrangisole(false)}
          >
            <Link
              to="/prodotti/frangisole"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgFrangisole ? (
                <img
                  src={imgFrangisole}
                  alt="prodotto"
                  className="product-img"
                />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div style={{ padding: "0 1rem" }}>
                {hoveredFrangisole ? (
                  <Slide
                    direction="up"
                    in={hoveredFrangisole}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h2>
                      Soluzioni per arredare gli spazi esterni con eleganza e
                      leggerezza
                    </h2>
                  </Slide>
                ) : (
                  <h1>Frangisole</h1>
                )}
              </div>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={4} className="center" style={{ paddingTop: "4rem" }}>
          <Card
            style={{
              borderRadius: !hoveredVele ? "30%" : "0",
              transition: "all 0.5s",
            }}
            elevation={3}
            className="product-card"
            onMouseEnter={() => setHoveredVele(true)}
            onMouseLeave={() => setHoveredVele(false)}
          >
            <Link
              to="/prodotti/vele"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgVele ? (
                <img src={imgVele} alt="prodotto" className="product-img" />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div style={{ padding: "0 1rem" }}>
                {hoveredVele ? (
                  <Slide
                    direction="up"
                    in={hoveredVele}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h2>
                      Soluzioni per arredare gli spazi esterni con eleganza e
                      leggerezza
                    </h2>
                  </Slide>
                ) : (
                  <h1>Vele</h1>
                )}
              </div>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={4} className="center" style={{ paddingTop: "4rem" }}>
          <Card
            style={{
              borderRadius: !hoveredCode ? "30%" : "0",
              transition: "all 0.5s",
            }}
            elevation={3}
            className="product-card"
            onMouseEnter={() => setHoveredCode(true)}
            onMouseLeave={() => setHoveredCode(false)}
          >
            <Link
              to="/prodotti/code"
              style={{ textDecoration: "none", color: "black" }}
            >
              {imgCode ? (
                <img src={imgCode} alt="prodotto" className="product-img" />
              ) : (
                <Skeleton variant="rect" width="100%" height="380px" />
              )}
              <div style={{ padding: "0 1rem" }}>
                {hoveredCode ? (
                  <Slide
                    direction="up"
                    in={hoveredCode}
                    mountOnEnter
                    unmountOnExit
                  >
                    <h2>
                      L’innovativo e rivoluzionario sistema a doghe in
                      alluminio, che si integra perfettamente a diverse
                      soluzioni
                    </h2>
                  </Slide>
                ) : (
                  <h1>Code</h1>
                )}
              </div>
            </Link>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
