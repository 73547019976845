import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Prodotti from "./components/Prodotti";
import Agevolazioni from "./components/Agevolazioni";
import Installazioni from "./components/Installazioni";
import Contatti from "./components/Contatti";
import { useMediaQuery } from "react-responsive";
import Footer from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import HomeMobile from "./components/HomeMobile";
import ProdottiMobile from "./components/ProdottiMobile";
import FooterMobile from "./components/FooterMobile";
import TendeDaEsterno from "./components/prodotti/TendeDaEsterno";
import Pergolati from "./components/prodotti/Pergolati";
import Bioclimatiche from "./components/prodotti/Bioclimatiche";
import Chiusure from "./components/prodotti/Chiusure";
import Pavimenti from "./components/prodotti/Pavimenti";
import Frangisole from "./components/prodotti/Frangisole";
import Vele from "./components/prodotti/Vele";
import Code from "./components/prodotti/Code";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      <div>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={isTabletOrMobile ? <HomeMobile /> : <Home />}
            />
            <Route
              path="/prodotti"
              element={isTabletOrMobile ? <ProdottiMobile /> : <Prodotti />}
            />
            <Route path="/prodotti/pergolati" element={<Pergolati />} />
            <Route path="/prodotti/bioclimatiche" element={<Bioclimatiche />} />
            <Route path="/prodotti/chiusure" element={<Chiusure />} />
            <Route path="/prodotti/pavimenti" element={<Pavimenti />} />
            <Route path="/prodotti/frangisole" element={<Frangisole />} />
            <Route path="/prodotti/vele" element={<Vele />} />
            <Route path="/prodotti/code" element={<Code />} />
            <Route
              path="/prodotti/tendeDaEsterno"
              element={<TendeDaEsterno />}
            />
            <Route path="/agevolazioni" element={<Agevolazioni />} />
            <Route exact path="/installazioni" element={<Installazioni />} />
            <Route exact path="/contatti" element={<Contatti />} />
          </Routes>
        </Router>
        {isTabletOrMobile ? <FooterMobile /> : <Footer />}
      </div>
    </>
  );
}

export default App;
