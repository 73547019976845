const data = {
  tendeDaEsterno: [
    {
      tendeABracci: [
        "r95_stone",
        "r75_stone",
        "r11_ghibli",
        "r15_garbi",
        "r80_dynamic",
        "r21_siroco",
        "r30_mistral",
        "r88_design",
        "r32_mistral",
        "r86_mistral",
        "r39_mistral_forged",
        "r60_levante",
        "r66_levante",
        "r51_ponant",
        "r70_avantgarde",
        "r90_elegance",
        "t87_ct",
        "r41_abrego",
        "r89_led_design",
        "r42_abrego",
        "r93_elegance",
        "r94_stone",
      ],
      descrizionetendeABracci: [
        "Tenda a cassonetto, dal design depositato caratterizzato da effetto “pietra”, con bracci a pantografo e luci integrate. La grande robustezza e le ridotte dimensioni sono le principali caratteristiche. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Regolazione micrometrica dell’inclinazione bracci. Funzionamento esclusivamente a motore. Campo di lavoro dei bracci da 5 a 45 gradi dall’orizzontale. Sporgenza minima mt. 3.00 massima mt. 4.25. Larghezza massima 8.00 mt.",
        "Tenda motorizzata con cassonetto a scomparsa totale per una perfetta protezione del telo e della struttura, dalla forma particolarmente compatta in modo tale da limitare l’ingombro in balconi dalle altezze contenute. Possibilità di attacco solo a soffitto. Carter laterali in alluminio a copertura di tutta la bulloneria. Regolazione micrometrica dell’inclinazione. Luci LED integrate nel profilo cassonetto. Design depositato caratterizzato da effetto 'pietra'. Verniciatura con polveri poliestere seguendo rigidi standard qualitativi. Ove possibile viteria esposta in acciaio inox. Tenda indicata per balconi e forniture condominiali. Dimensioni massime di mt. 5.00 di larghezza e mt. 2.40 di sporgenza. Possibilità movimentazione manuale.",
        "Tenda a supporti indipendenti,con bracci a pantografo. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Tenda di ottimo aspetto estetico con sistema di regolazione semplificato. Dimensioni contenute e grande robustezza sono le sue caratteristiche principali. Struttura consigliata per dimensioni massime di mt. 4.80 di larghezza e mt. 2.40 di sporgenza.",
        "Tenda a supporti indipendenti con nuovo terminale ideato e realizzato per un accoppiamento perfetto a livello estetico / funzionale. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Modello ideale per la copertura di balconi, negozi e forniture condominiali. Sistema di regolazione a vite micrometrica completamente carenato. Inserimento del rullo avvolgitore di tipo frontale. Struttura consigliata per dimensioni massime di mt. 5.00 di larghezza e mt. 3.00 di sporgenza.",
        "Tenda premontata su barra quadra da 40mm x 40mm, con bracci a pantografo. Il binomio di robustezza e minimo ingombro, rendono la struttura di facile installazione. Elementi strutturali in lega d’alluminio pressofusa. Supporti braccio dotati di un semplificato sistema a regolazione micrometrica. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita seguendo rigidi standard qualitativi. Modello ideale per copertura di balconi, negozi e condomini. Struttura consigliata per dimensioni massime di larghezza 5,00 mt. e sporgenza 2,60 mt.",
        "Tenda a supporti indipendenti con nuovo terminale ideato e realizzato per un accoppiamento perfetto a livello estetico / funzionale. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Modello ideale per la copertura di balconi, negozi e forniture condominiali. Sistema di regolazione a vite micrometrica completamente carenato. Inserimento del rullo avvolgitore di tipo frontale. Struttura consigliata per dimensioni massime di mt. 5.00 di larghezza e mt. 3.00 di sporgenza.",
        "Tenda su barra quadra, da mm. 40×40, con bracci a pantografo. La grande robustezza e l’estetica curata sono le principali caratteristiche. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Tenda con componentistica in lega pressofusa, supporto braccio completamente carenato e dotato di regolazione dell’allineamento bracci a tenda avvolta. Regolazione micrometrica dell’inclinazione bracci. Modello ideale per la copertura residenziale di balconi e negozi. Struttura consigliata per dimensioni massime di mt. 12.00 di larghezza e mt. 3.50 di sporgenza.",
        "Tenda premontata su barra quadra da 40 x 40 mm, con bracci a pantografo. Design tondo, essenziale e curato, unito ad un’ottima robustezza, rendono la struttura unica e di facile apprezzamento. Elementi strutturali in lega d’alluminio pressofusa. Supporti braccio dotati di un semplificato sistema e regolazione micrometrica. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita seguendo rigidi standard qualitativi. Modello ideale per copertura di balconi, negozi e condomini. Struttura consigliata per dimensioni massime di larghezza 12,00 mt. e sporgenza 3,50 mt.",
        "Tenda a barra quadra da mm. 40×40, con bracci a pantografo di grande robustezza e versatilità. I supporti offrono la possibilità di sormontare, quasi completamente, i bracci uno sull’altro, riducendo quello che è l’ingombro minimo in larghezza, privilegiando la sporgenza. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita seguendo rigidi standard qualitativi. Tenda con componentistica in lega pressofusa, supporto braccio completamente carenato e dotato di regolazione dell’allineamento bracci a tenda avvolta. Regolazione micrometrica dell’inclinazione bracci. Modello ideale per la copertura residenziale di balconi e negozi dalle larghezze minime e sporgenze elevate.",
        "Tenda con bracci a pantografo, caratterizzata da una doppia e accentuata inclinazione del telo. Supporti montati su barra quadra, da mm 40×40. Profilo frangivento intermedio incernierato sui gomiti dei bracci tramite carrelli autocentranti guidati a cavo. Viteria in acciaio inox. Verniciatura con polveri poliestere seguendo rigidi standard qualitativi. Struttura con componentistica in lega pressofusa, supporto braccio completamente carenato e dotato di regolazione dell’allineamento bracci a tenda avvolta. Modello che concede un’ampia copertura verticale con una sporgenza ridotta. Ideale per coperture residenziali di balconi e negozi, soprattutto per ampie decorazioni pubblicitarie. Larghezza massima consigliata mt. 6.00. Sporgenza massima consigliata mt. 3.00.",
        "Tenda a barra quadra rinforzata, da mm. 40×40, con bracci a pantografo in alluminio forgiato a caldo. La grande robustezza e le ridotte dimensioni sono le principali caratteristiche. Viteria esposta in acciaio inox ove possibile. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Regolazione micrometrica dell’inclinazione bracci. Funzionamento esclusivamente a motore. Campo di lavoro dei bracci da 5° a 30° dall’orizzontale. Oltre mt. 7.00 di larghezza funzionamento con due motori montati sullo stesso rullo con relativa centralina di comando. Sporgenza minima mt. 3.50, massima mt. 4.25. Larghezza massima mt. 14.00.",
        "Tenda a bracci giganti con supporti indipendenti da fissare a parete e barra quadra ausiliaria. La grande robustezza, il sistema di installazione semplificato e l’estetica curata sono le principali caratteristiche. Ove possibile viteria esposta in acciaio inox. Verniciatura con polveri poliestere con rigidi standard qualitativi. È ideale per locali pubblici, terrazzi, portoni di ingresso per capannoni e ovunque vi sia la necessità di realizzare coperture di grandi dimensioni. Regolazione micrometrica dei bracci. Funzionamento esclusivamente a motore. Campo di lavoro dei bracci da 5° a 35° dall’orizzontale. Oltre mt. 7.00 di larghezza funzionamento con due motori montati sullo stesso rullo con relativa centralina di comando. Sporgenza minima mt. 4.00, massima mt. 5.50. Larghezza massima mt. 14.00.",
        "Tenda con bracci giganti a pantografo, caratterizzata da una doppia inclinazione del telo. Supporti montati su barra quadra, da mm 40×40. Profilo frangivento intermedio, incernierato sui gomiti dei bracci, tramite carrelli autocentranti, guidati a cavo. Tale sistema riduce la formazione di sacche d’acqua anche in condizioni di minima pendenza del telo. Ove possibile viteria esposta in acciaio inox. Verniciatura con polveri poliestere seguendo rigidi standard qualitativi. Ideale per locali pubblici, terrazzi, portoni di ingresso, capannoni e ovunque vi sia la necessità di realizzare coperture di grandi dimensioni. Regolazione micrometrica dei bracci. Campo di lavoro dei bracci da 10° a 44° dall’orizzonte. Sporgenza minima mt. 4.00, massima mt. 5.50. Larghezza massima mt. 7.00.",
        "Tenda caratterizzata da un cassonetto, a scomparsa totale, che racchiude al suo interno il tessuto per una completa protezione dello stesso. Cassonetto di grande semplicità e ottimo aspetto estetico. Ingombri contenuti e ottima robustezza sono le sue caratteristiche. Regolazione dell’inclinazione bracci di tipo micrometrico. I supporti, offrono la possibilità di sormontare, quasi completamente, i bracci uno sull’altro, riducendo quello che è l’ingombro minimo in larghezza, privilegiando la sporgenza. Modello ideale per la copertura residenziale di balconi e negozi dalle larghezze minime e sporgenze elevate.",
        "Tenda caratterizzata da un cassonetto, a scomparsa parziale, che racchiude al suo interno il tessuto per una completa protezione dello stesso. Cassonetto di grande semplicità e ottimo aspetto estetico. Ingombri contenuti e ottima robustezza sono le sue caratteristiche. Regolazione dell’inclinazione bracci di tipo micrometrico. I supporti, offrono la possibilità di sormontare, quasi completamente, i bracci uno sull’altro, riducendo quello che è l’ingombro minimo in larghezza, privilegiando la sporgenza. Modello ideale per la copertura residenziale di balconi e negozi dalle larghezze minime e sporgenze elevate.",
        "Tenda a cassonetto totale dal design morbido, compatto ed elegante. La struttura adotta combinati sistemi micrometrici di regolazione del cassonetto e delle braccia per una rapida installazione. La particolare conformazione delle piastre e dei tappi, garantisce una corretta chiusura degli elementi. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Modello ideale per la copertura di balconi, negozi e forniture condominiali. Struttura consigliata per dimensioni massime di larghezza 4,80 mt. e sporgenza 2,40 mt.",
        "Tenda con bracci a pantografo con bicavo e cassonetto a scomparsa totale. Unisce alla protezione dei bracci e del tessuto una linea elegante. Dotata di spazzolino per la pulizia del telo. Verniciatura con polveri poliestere seguendo rigidi standard qualitativi. Struttura dalla grande praticità di installazione prevista per essere montata sia a parete che a soffitto, indicata per balconi, terrazzi e forniture condominiali. Regolazione dell’inclinazione di tipo micrometrico. Campo di lavoro dei bracci dai 0° a 68° se installata a parete, 10° – 90° se a soffitto. Larghezza massima mt. 5.00. Sporgenza massima mt. 3.00.",
        "Tenda caratterizzata da un cassonetto, a scomparsa parziale, che racchiude al suo interno il tessuto per una completa protezione dello stesso. Cassonetto di grande semplicità e ottimo aspetto estetico. Ingombri contenuti e ottima robustezza sono le sue caratteristiche. Regolazione dell’inclinazione bracci di tipo micrometrico. Struttura consigliata per dimensioni massime di mt. 4.80 di larghezza e mt. 2.20 di sporgenza.",
        "Tenda premontata su barra quadra da 40 x 40 mm, con bracci a pantografo con led. Design tondo, essenziale e curato, unito ad un’ottima robustezza, rendono la struttura unica e di facile apprezzamento. Elementi strutturali in lega d’alluminio pressofusa. Supporti braccio dotati di un semplificato sistema e regolazione micrometrica. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita seguendo rigidi standard qualitativi. Modello ideale per copertura di balconi, negozi e condomini. Struttura consigliata per dimensioni massime di larghezza 8,00 mt. e sporgenza 3,00 mt. Tenda confezionata: fino a larghezza 6 mt. assemblata senza giunzioni; da 6 mt. a 8 mt. di larghezza pre-assemblata senza giunzioni.",
        "Tenda caratterizzata da un cassonetto, a scomparsa parziale, che racchiude al suo interno il tessuto per una completa protezione dello stesso. Cassonetto di grande semplicità e ottimo aspetto estetico. Ingombri contenuti e ottima robustezza sono le sue caratteristiche. Regolazione dell’inclinazione bracci di tipo micrometrico. I supporti, offrono la possibilità di sormontare, quasi completamente, i bracci uno sull’altro, riducendo quello che è l’ingombro minimo in larghezza, privilegiando la sporgenza. Modello ideale per la copertura residenziale di balconi e negozi dalle larghezze minime e sporgenze elevate.",
        "Tenda a cassonetto totale dal design morbido, compatto ed elegante. La struttura adotta combinati sistemi micrometrici di regolazione del cassonetto e delle braccia per una rapida installazione. La particolare conformazione delle piastre e dei tappi, garantisce una corretta chiusura degli elementi. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Modello ideale per la copertura di balconi, negozi e forniture condominiali. Struttura consigliata per dimensioni massime di larghezza 8,00 mt. e sporgenza 3,50 mt.",
        "Tenda motorizzata con cassonetto a scomparsa totale per una perfetta protezione del telo e della struttura, dalla forma particolarmente compatta in modo tale da limitare l’ingombro in balconi dalle altezze contenute. Possibilità di attacco a soffitto e parete. Carter laterali in alluminio a copertura di tutta la bulloneria. Regolazione micrometrica dell’inclinazione. Luci led integrate nei bracci. Design depositato caratterizzato da effetto “pietra”. Verniciatura con polveri poliestere seguendo rigidi standard qualitativi. Ove possibile viteria esposta in acciaio inox. Tenda indicata per balconi e forniture condominiali. Tenda consigliata per dimensioni massime di mt. 5.00 di larghezza e mt. 3.00 di sporgenza.",
      ],
      cappottine: [
        "base3C-4C",
        "modulare",
        "curvilineo",
        "sferica_ovale",
        "semitonda",
        "angolare",
        "showroom",
        "teorema",
        "triangolo_fissa",
        "triangolo_semirigida",
      ],
      descrizionecappottine: [
        "Tende con richiamo a fune, particolarmente indicate per esercizi commerciali ed in genere vetrine e finestre. Struttura verniciata con polveri poliestere presso il nostro stabilimento seguendo rigidi standard qualitativi. Struttura con telaio in lega estrusa e articolazioni in resina termoplastica, oppure in alluminio. Installabile sia a parete, che a soffitto o in “spallina”. – Larghezza massima mt. 5.00. – Sporgenza massima mt. 1.60.",
        "Tende con richiamo a fune, particolarmente indicate per esercizi commerciali ed in genere vetrine e finestre. Struttura verniciata con polveri poliestere presso il nostro stabilimento seguendo rigidi standard qualitativi. Struttura con telaio in lega estrusa e articolazioni in resina termoplastica. Installabile sia a parete, che a soffitto o in “spallina”. – Larghezza massima mt. 5.00. – Sporgenza massima mt. 1.60.",
        "Tende con richiamo a fune, particolarmente indicate per esercizi commerciali ed in genere vetrine e finestre. Struttura verniciata con polveri poliestere presso il nostro stabilimento seguendo rigidi standard qualitativi. Struttura con telaio in lega estrusa e articolazioni in resina termoplastica. Installabile sia a parete, che a soffitto o in “spallina”. – Larghezza massima mt. 5.00. – Sporgenza massima mt. 1.60.",
        "Tende con richiamo a fune, particolarmente indicate per esercizi commerciali ed in genere vetrine e finestre. Struttura verniciata con polveri poliestere presso il nostro stabilimento seguendo rigidi standard qualitativi. Struttura con telaio in lega estrusa e articolazioni in resina termoplastica. Installabile sia a parete, che a soffitto o in “spallina”. – Larghezza massima mt. 5.00. – Sporgenza massima mt. 1.60.",
        "Tende con richiamo a fune, particolarmente indicate per esercizi commerciali ed in genere vetrine e finestre. Struttura verniciata con polveri poliestere presso il nostro stabilimento seguendo rigidi standard qualitativi. Struttura con telaio in lega estrusa e articolazioni in resina termoplastica. Installabile sia a parete, che a soffitto o in “spallina”. – Larghezza massima mt. 5.00. – Sporgenza massima mt. 1.60.",
        "Tende con richiamo a fune, particolarmente indicate per esercizi commerciali ed in genere vetrine e finestre. Struttura verniciata con polveri poliestere presso il nostro stabilimento seguendo rigidi standard qualitativi. Struttura con telaio in lega estrusa e articolazioni in resina termoplastica. Installabile sia a parete, che a soffitto o in “spallina”. – Larghezza massima mt. 5.00. – Sporgenza massima mt. 1.60.",
        "Struttura in tubolare di alluminio D.40 mm. con componenti e articolazioni fuse in lega d’alluminio. Struttura semifissa ripiegabile particolarmente indicata per ingressi e negozi. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Dimensioni massime per modello show-room: Larghezza mt. 6.00, sporgenza mt. 2.00.",
        "Struttura in tubolare di lega d’alluminio D.40 di tipo fisso con componenti e articolazioni fuse in lega di alluminio. Particolarmente indicate per ingressi e per l’arredo urbano. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi.",
        "Struttura in tubolare di alluminio D.40 mm. con componenti e articolazioni in lega d’alluminio. Struttura particolarmente indicata per ingressi e negozi. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Dimensioni massime: Larghezza mt. 4.00, sporgenza mt. 1.28.",
        "Struttura in tubolare di alluminio D.40 mm. con componenti e articolazioni in lega d’alluminio. Struttura particolarmente indicata per ingressi e negozi. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Dimensioni massime: Larghezza mt. 4.00, sporgenza mt. 1.28.",
      ],
      guideLaterali: [
        {
          nome: "andalucia",
          descrizione:
            "Tenda con guide sopra il telo, da applicare in posizione variabile, profili orizzontali scorrevoli che permettono alla tenda il caratteristico aspetto “a onde” (ogni 50 cm circa). Funzionante a fune e rinvii con carrucole. Verniciatura con polveri poliestere eseguita con rigidi standard qualitativi. Ove possibile viteria esposta in acciaio inox. Tenda indicata per applicazioni sotto pergole, verande, lucernai. Tenda consigliata per dimensioni massime di mt. 5.00 di larghezza e mt. 5.00 di sporgenza.",
        },
        {
          nome: "andalucia_light",
          descrizione:
            "Tenda con guide sopra il telo, da applicare in posizione variabile, profili orizzontali scorrevoli che permettono alla tenda il caratteristico aspetto “a onde” (ogni 50 cm circa). Funzionante a fune e rinvii con carrucole. Verniciatura con polveri poliestere eseguita con rigidi standard qualitativi. Ove possibile viteria esposta in acciaio inox. Tenda indicata per applicazioni sotto pergole, verande, lucernai. Montaggio diretto a soffitto o con squadrette per spallina. Tenda consigliata per dimensioni massime di mt. 4.00 di larghezza e mt. 4.00 di sporgenza.",
        },
        {
          nome: "andalucia_maxi",
          descrizione:
            " Tenda autoportante con piantane 13×8 cm. e guide sopra il telo, profili orizzontali scorrevoli che permettono alla tenda il caratteristico aspetto “a onde” (ogni 50 cm circa). Funzionante a fune e rinvii con carrucole. Verniciatura con polveri poliestere eseguita con rigidi standard qualitativi. Ove possibile viteria esposta in acciaio inox. Tenda consigliata per dimensioni massime di mt. 4.00 di larghezza e mt. 5.00 di sporgenza.",
        },
        {
          nome: "avvolgibile",
          descrizione:
            "Tenda con guide sopra il telo, da applicare in posizione variabile, profili orizzontali scorrevoli che permettono alla tenda il caratteristico aspetto “a onde” (ogni 50 cm circa). Funzionante a fune e rinvii con carrucole. Verniciatura con polveri poliestere eseguita con rigidi standard qualitativi. Ove possibile viteria esposta in acciaio inox. Tenda indicata per applicazioni sotto pergole, verande, lucernai. Tenda consigliata per dimensioni massime di mt. 5.00 di larghezza e mt. 5.00 di sporgenza.",
        },
        {
          nome: "tsp",
          descrizione:
            " Struttura a guide laterali con scorrimento del telo sopra le guide. Funzionamento ad argano o a motore. Applicabile sia a parete che a soffitto. Verniciatura con polveri poliestere eseguita presso il nostro stesso stabilimento con rigidi standard qualitativi. La struttura è caratterizzata da una notevole semplicità e buona robustezza. Struttura indicata per la copertura di superfici come terrazzi, locali pubblici e ovunque sia necessario ottenere anche una copertura frontale. Sporgenza massima mt. 5.00. Larghezza massima mt. 5.00 Altezza massima mt. 3.00. Inclinazione della tenda tra i 10° e 25° corrisponde ad una pendenza tra il 18% e il 46% della sporgenza (P).",
        },
        {
          nome: "tspq",
          descrizione:
            "Struttura a guide laterali montata su barra quadra da mm. 40×40 installabile sia a parete che a soffitto. Scorrimento del telo sopra le guide sia ad argano che a motore. Applicabile ad un sistema di tensionamento del telo di ottima efficienza e funzionalità. Verniciatura con polveri poliestere eseguita con rigidi standard qualitativi. La struttura è caratterizzata da una notevole semplicità e grande robustezza. Struttura indicata per la copertura di terrazzi, locali pubblici e superfici di medie o grandi dimensioni. Inoltre, dove necessario, permette di ottenere anche una copertura frontale. Campo di lavoro della struttura da 10° a 30° dall’orizzontale. Sporgenza massima mt. 6.00. Larghezza massima mt. 10,80 h. max. mt. 3,00. Inclinazione della tenda tra i 10° e 30° corrisponde ad una pendenza tra il 18% e il 58% della Sporgenza (P).",
        },
      ],
      gazebi: [
        {
          nome: "r501",
          descrizione:
            "Struttura in acciaio molto resistente che si può adattare a qualsiasi tipo di esigenza. Telo in PVC ignifugo in tinta unita che può essere completato dalle pareti, scorrevoli o fisse, che vengono allacciate almontante tramite le apposite cinghie. Profilo piantana diam. 60x60mm. Profilo perimetrale da 80x40mm.",
        },
        {
          nome: "r511",
          descrizione:
            "Struttura in acciaio molto resistente che si può adattare a qualsiasi tipo di esigenza. Telo in PVC ignifugo in tinta unita che può essere completato dalle pareti, scorrevoli o fisse, che vengono allacciate al montante tramite le apposite cinghie. Profilo piantana 150x150mm. Profilo perimetrale da 100x140mm (R511). Profilo piantana 175x175mm. Profilo perimetrale da 200x140mm (R511 MAXI).",
        },
        {
          nome: "r565",
          descrizione:
            "La struttura è realizzata in legno Ø 50 mm verniciato per esterni, con palo in essenza di ayous, stecche (30x18mm) a sgancio rapido in essenza di pino e collare, corona e puntale in essenza di ontano. Il meccanismo di apertura/chiusura a carrucola è dotato di fune in treccia 100% poliestere e spina in metallo per il bloccaggio.",
        },
        {
          nome: "r566",
          descrizione:
            "La struttura è realizzata in legno Ø 50 mm verniciato per esterni, con palo in essenza di ayous, stecche (30x18mm) a sgancio rapido in essenza di pino e collare, corona e puntale in essenza di ontano.Il meccanismo di apertura/chiusura a carrucola è dotato di fune in treccia 100% poliestere e spina in metallo per il bloccaggio.",
        },
        {
          nome: "r567",
          descrizione:
            "La struttura è realizzata in metallo verniciato a polveri con palo in alluminio (74x74mm), stecche in alluminio (30x15mm) decorate con scanalature, tappi in plastica PE, collare e corona in plastica ABS e puntale in nylon. Il meccanismo di apertura/chiusura con arganello a manovella e carrucole per lo scorrimento è dotato di fune in treccia d’acciaio per uso nautico.",
        },
      ],
    },
  ],
  bioclimatiche: [
    "r601_pergoklima",
    "r603_pergoklima",
    "r605_pergoklima",
    "r610_pergoklima",
    "r612_pergoklima",
    "r620_pergoklima",
    "r621_pergoklima",
    "r623_pergoklima",
    "r641_pergospace",
    "r642_pergospace",
    "r650_pergospace",
  ],
  descrizionebioclimatiche: [
    "",
    "",
    "Pergola bioclimatica autoportante in alluminio dal design ricercato e innovativo. Gronda perimetrale da 20 x 20 cm. con raccolta acqua integrata nei montanti di forma triangolare. Copertura a lamelle in alluminio da L 22cm x H 4cm orientabili da 0° a 140°, complete di guarnizioni e gronde scarico acqua laterale. In base all’orientamento delle lamelle, la struttura, offre protezione totale o parziale dal sole, una perfetta climatizzazione, e un sicuro riparo dalla pioggia divenendo uno spazio da vivere sempre. Dimensioni massime realizzabili in modulo unico L 4.50 x S 5.00 mt. con quattro montanti perimetrali triangolari da 20 x 20 cm. Funzionamento esclusivo a motore lineare a bassa tensione. La struttura, offre optional aggiuntivi, come luci LED o teli a drappeggio, in grado di creare sinergie tra il nuovo ambiente e quello circostante, che generano nuove soluzioni ogni volta originali e personalizzate. I profili portanti consentono l’applicazione di frangisole e vetrate.",
    "Pergola bioclimatica autoportante o addossata a parete in alluminio dal design ricercato e innovativo. Gronda perimetrale da 20 x 20 cm. con raccolta acqua integrata nei montanti. Copertura a lamelle in alluminio da L 22cm x H 4cm orientabili da 0° a 140°, complete di guarnizioni e scarico acqua laterale. In base all’orientamento delle lamelle, la struttura,offre protezione totale o parziale dal sole, una perfetta climatizzazione, e sicuro riparo dalla pioggia divenendo uno spazio da vivere sempre. Dimensioni massime realizzabili in modulo unico L 4.20 x SP 6.00 mt. con solo due montanti perimetrali da 20 x 20 cm. Funzionamento esclusivo a motore lineare a bassa tensione. La struttura, offre optional aggiuntivi, come luci LED o teli a drappeggio, in grado di creare sinergie tra il nuovo ambiente e quello circostante, che generano nuove soluzioni ogni volta originali e personalizzate. I profili portanti consentono l’applicazione di frangisole e vetrate che ben si integrano alle tipiche chiusure a rullo.",
    "Pergola bioclimatica per ingressi, addossata a parete, in alluminio dal design ricercato e innovativo. Gronda perimetrale da 20 x 20 cm. con raccolta acqua integrata nei montanti laterali. Copertura a lamelle in alluminio da L 22cm x H 4cm orientabili da 0° a 140°, complete di guarnizioni e gronde scarico acqua laterale. In base all’orientamento delle lamelle, la struttura, offre protezione totale o parziale dal sole, una perfetta climatizzazione, e un sicuro riparo dalla pioggia divenendo uno spazio da vivere sempre. Dimensioni massime realizzabili in modulo unico L 4.50 x S 5.00 mt. con due montanti laterali da 20 x 10 cm. Funzionamento esclusivo a motore lineare a bassa tensione. La struttura, offre optional aggiuntivi, come luci LED o teli a drappeggio, in grado di creare sinergie tra il nuovo ambiente e quello circostante, che generano nuove soluzioni ogni volta originali e personalizzate.",
    "Pergola bioclimatica autoportante in alluminio dal design ricercato e innovativo. Gronda perimetrale da 20 x 16,5 cm. con raccolta acqua integrata nei montanti. Copertura a lamelle in alluminio da L 22cm x H 4cm orientabili da 0 a 140 gradi, complete di guarnizioni e gronde scarico acqua laterale. In base all’orientamento delle lamelle, la struttura, offre protezione totale o parziale dal sole, una perfetta climatizzazione, e un sicuro riparo dalla pioggia divenendo uno spazio da vivere sempre. Dimensioni massime realizzabili in modulo unico L 3.50 x S 5.10 mt. con quattro montanti perimetrali da 13 x 13 cm. Funzionamento esclusivo a motore lineare a bassa tensione. La struttura, offre optional aggiuntivi, come luci LED o teli a drappeggio, in grado di creare sinergie tra il nuovo ambiente e quello circostante, che generano nuove soluzioni ogni volta originali e personalizzate.",
    "",
    "",
    "",
    "",
    "",
  ],
  pergolati: [
    {
      piantaneAnteriori: [
        "r127_pergostyle",
        "r128_pergotesa",
        "r130_pergogarden",
        "r140_pergosmall",
        "r228_pergotesa",
        "r230_pergomaster",
        "r231_pergomaster",
      ],
      descrizionepiantaneAnteriori: [
        "Sistema di trazione con cinghia dentata ad alta resistenza, funzionante su cuscinetti a sfera e carrelli particolarmente silenziosi. La copertura mobile, a telo unico, in tessuto PVC ignifugo di ottima stabilità dimensionale, è indicata per la protezione dal sole, dalla pioggia e dal vento. Possibilità di realizzare coperture dalle forme asimmetriche con sporgenze laterali fuori squadra che si adattano facilmente alle diverse esigenze. Il modulo minimo realizzabile è di mt. 0,90 di larghezza e mt. 0,90 di sporgenza.",
        "Pergolato dall’estetica perfezionata con gronda incorporata per lo scarico delle acque, dal buon rapporto qualità/prezzo. Una struttura che rappresenta la naturale estensione dell’ edificio all’aperto, che consentirà di creare nuove zone coperte dove trascorrere piacevoli momenti in giardino o in terrazzo in tutte le stagioni. R128 PERGOTESA rappresenta un’efficace barriera al caldo d’estate ed al freddo d’inverno, garantendo un maggiore isolamento termico dell’edificio con il conseguente risparmio energetico.",
        "L’innovativo sistema integrato nelle piantane in legno, consente lo scarico delle acque senza alcun ingombro esterno. La copertura mobile, a telo unico, in tessuto PVC ignifugo di ottima stabilità dimensionale, è indicata per la protezione dal sole, dalla pioggia e dal vento. Movimentazione a motore e su richiesta con comando radio. Possibilità di integrare PERGOGARDEN con tende a rullo frontali e laterali complete di guide verticali antivento. Il modulo minimo realizzabile è di mt. 0,90 di larghezza e mt. 0,90 di sporgenza. Struttura portante in lamellare di pino impregnato in autoclave e tinto color noce, bianco o grigio.",
        "PERGOSMALL valorizza diversi ambienti: dall’arredo tradizionale a quello moderno per una location raffinata e di tendenza.Sistema di trazione con cinghia dentata ad alta resistenza, funzionante su cuscinetti a sfere e carrelli particolarmente silenziosi. La copertura mobile, a telo unico, in tessuto PVC ignifugo di ottima stabilità dimensionale, è indicata per la protezione dal sole, dalla pioggia e dal vento. La gronda per lo scarico dell’acqua è parte integrante della struttura. Possibilità di realizzare coperture dalle forme asimmetriche con sporgenze laterali fuori squadra che si adattano facilmente alle diverse esigenze. Movimentazione a motore e su richiesta con comando radio. Il modulo minimo realizzabile è di mt. 0,90 di larghezza e mt. 0,90 di sporgenza.",
        "Pergolato dall’estetica perfezionata con gronda incorporata per lo scarico delle acque, dal buon rapporto qualità/prezzo. Una struttura che rappresenta la naturale estensione dell’ edificio all’aperto, che consentirà di creare nuove zone coperte dove trascorrere piacevoli momenti in giardino o in terrazzo in tutte le stagioni. R228 PERGOTESA rappresenta un’efficace barriera al caldo d’estate ed al freddo d’inverno, garantendo un maggiore isolamento termico dell’edificio con il conseguente risparmio energetico.",
        "Un design inedito e un concentrato di tecnologia: un brevetto europeo è alla base di questa pergola di nuova generazione. Grazie al nostro esclusivo sistema “a teli separati”, il montaggio e la manutenzione delle singole strisce di tessuto risulta facilitato in quanto vengono gestite come elementi indipendenti sfilabili, con la possibilità di spaziare con diverse tonalità di colore dal forte effetto scenografico. I moduli di questa struttura, multifunzionale, arrivano a coprire una superficie fino a 10 metri di profondità, adatti a diventare l’estrema appendice di abitazioni o luoghi pubblici. Mai come in precedenza, la pergola diventa un elemento d’arredo degli spazi aperti, prolungando il periodo di utilizzo della location inserendosi bene in ogni contesto sia naturale che architettonico. Pergomaster, un’armonia perfetta: dedicata a tutti coloro che intendono vivere con eleganza ed emozione il proprio spazio all’aperto.",
        "Un design inedito e un concentrato di tecnologia. I moduli di questa struttura, multifunzionale, arrivano a coprire una superficie fino a 9 metri di profondità, adatti a diventare l’estrema appendice di abitazioni o luoghi pubblici. Mai come in precedenza, la pergola diventa un elemento d’arredo degli spazi aperti, prolungando il periodo di utilizzo della location inserendosi bene in ogni contesto sia naturale che architettonico.Pergomaster, un’armonia perfetta: dedicata a tutti coloro che intendono vivere con eleganza ed emozione il proprio spazio all’aperto.",
      ],
      pianeEAutoportanti: [
        "r129_pergostyle",
        "r150_pergosquare",
        "r210_pergolife",
        "r212_pergolife",
      ],
      descrizionepianeEAutoportanti: [
        "Sistema di trazione con cinghia dentata ad alta resistenza, funzionante su cuscinetti a sfera e carrelli particolarmente silenziosi. La copertura mobile, a telo unico, in tessuto PVC ignifugo di ottima stabilità dimensionale, è indicata per la protezione dal sole, dalla pioggia e dal vento. Possibilità di realizzare coperture dalle forme asimmetriche con sporgenze laterali fuori squadra che si adattano facilmente alle diverse esigenze. È infatti possibile realizzare strutture fuori standard previo studio ed approvazione dell’ufficio tecnico. Movimentazione a motore e su richiesta con comando radio. Il modulo minimo realizzabile è di mt. 0,90 di larghezza e mt. 0,90 di sporgenza.",
        "PERGOSQUARE soddisfa l’esigenza di un’installazione di grande impatto visivo ed emotivo: un valore che dura nel tempo e rende esclusivo qualsiasi ambiente. Struttura autoportante dal funzionamento a motore e su richiesta a comando radio. Profili portanti in alluminio estruso di sezione 130x80mm assiemati da eleganti staffe in acciaio verniciato. Sistema di trazione con cinghia dentata ad alta resistenza, funzionante su cuscinetti a sfera e carrelli, particolarmente silenziosi. La copertura mobile, a telo in strisce di tessuto PVC ignifugo di ottima stabilità dimensionale, è indicata per la protezione dal sole e dalla pioggia. Teli separati con doppia pendenza laterale per lo scarico dell’acqua, dall’ingombro ridotto della sacca. Possibilità di scaricare l’acqua da un solo lato della struttura o in modo alternato, e di integrare nei profili ovalina, il contro telo inferiore ad onde in tessuto acrilico, per un ottimale comfort estetico. Movimentazione ad argano, come optional.",
        "Pergola addossata a parete in alluminio dal design ricercato e innovativo. Bordatura laterale H. 60 x 20 cm. di base con raccolta acqua integrata nei montanti. Copertura mobile realizzata in tessuto PVC oscurante ignifugo a telo teso con scarico acqua laterale. Dimensioni massime realizzabili a telo unico 8.00 x 8.00 mt. con solo due montanti perimetrali. Montanti 20 x 20 cm. PERGOLIFE offre optional aggiuntivi, come luci LED o teli a drappeggio, in grado di creare sinergie tra il nuovo ambiente e quello circostante, che generano nuove soluzioni ogni volta originali e personalizzate. I profili portanti consentono l’applicazione di frangisole e vetrate che ben si integrano alle tipiche chiusure a rullo.",
        "Struttura addossata a parete con telo teso e profili perimetrali dalle dimensioni ridotte, che includono la gronda di scarico. Qualità e precisione senza compromessi cosi si può descrivere R212 PERGOLIFE, che come scopo oltre alla qualità superiore del prodotto, offre la semplicità e rapidità del montaggio, grazie ad una serie di applicazioni appositamente studiate. La progettazione particolare dei componenti consente un assemblaggio semplice, veloce e preciso con risultati estetici davvero eccezionali.",
      ],
      senzaPiantane: [
        "r126_pergotesa",
        "r131_pergogarden",
        "r240_galaxy",
        "r241_arco",
        "r242_pergorod",
      ],
      descrizionesenzaPiantane: [
        "Pergola per applicazione su strutture esistenti, dal design moderno e funzionale, realizzata completamente in lega d’alluminio con viteria in acciaio inox, completa di staffe per l’ancoraggio a parete/soffitto. Esclusivo funzionamento con motore BT PERGOMOTOR adeguato alle caratteristiche di portata, con testa e freno rinforzati. Possibilità di applicazione del profilo antivento laterale (optional). Particolarmente indicata per coperture di lucernari, abbaini di grandi dimensioni.",
        "Pergola per applicazione su strutture esistenti con staffe e supporti in acciaio verniciato. Telo unico per impacchettamento funzionante con sistema a cinghia dentata. Guide in alluminio dal minimo ingombro. Particolarmente indicata per coperture di lucernari ed abbaini. Funzionamento con motore BT PERGOMOTOR adeguato alle caratteristiche di portata, con testa e freno rinforzati. Possibilità di applicazione del profilo antivento laterale (optional).",
        "Struttura in alluminio a sbalzo dal design innovativo con inclinazione regolabile. Copertura realizzata in tessuto PVC oscurante ignifugo a telo teso. Elementi di sostegno composti da doppio profilo quadro da 4 cm. in acciaio. Disco centrale in alluminio con sistema di regolazione dell’inclinazione. Nessun montante frontale verticale. Dimensioni massime realizzabili modulo singolo 4.50 mt. di larghezza per 5.00 mt. di sporgenza. Dimensioni massime realizzabili modulo triplo a teli divisi 12.00 mt. di larghezza per 5.00 mt di sporgenza.",
        "Struttura dal design moderno e funzionale, realizzata completamente in lega d’alluminio addossata alla parete tramite il sostegno di archi a supporto in acciaio zincato e verniciato.",
        "Struttura addossata a parete sostenuta mediante tiranti superiori, elegante e dal design innovativo, PERGOROD rappresenta una copertura versatile che si integra perfettamente con qualsiasi edificio. Questa copertura inoltre è estremamente funzionale, grazie alla particolare struttura permette di lasciare libero lo spazio sottostante, offrendovi la massima flessibilità di installazione.",
      ],
      solarium: ["r110_solarium"],
      descrizionesolarium: [
        "Struttura verniciata con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Copertura indicata per giardini, terrazzi e applicazione su pergolati esistenti. Si consiglia l’utilizzo del prodotto con “PVC 302”. In questo caso, il telo verrà confezionato con occhioli alla base, per poter usufruirne in caso di pioggia poco consistente. In caso di realizzazione con tessuto acrilico, si potranno verificare ondulazioni ai lati, in prossimità delle cuciture, soprattutto se il telo verrà riavvolto umido o bagnato.",
      ],
      pergozip: [
        "r170_pergozip",
        "r171_pergozip",
        "r172_pergozip",
        "r173_pergozip",
      ],
      descrizionepergozip: [
        "Pergola ombreggiante dal design puro e minimale, dotata di grande praticità, eleganza funzionale e resistenza. Il telo viene avvolto nel cassonetto d’alluminio e tensionato tramite molle a gas e zip laterali.Qualità BT Group, che riconosci subito al primo sguardo.Pergola con piantane frontali da 13×13 cm., con predisposizione per lo scarico delle acque.Si può installare a so tto o a parete.",
        "Pergola ombreggiante dal design puro e minimale, dotata di grande praticità, eleganza funzionale e resistenza. Il telo viene avvolto nel cassonetto d’alluminio e tensionato tramite molle a gas e zip laterali.Qualità BT Group, che riconosci subito al primo sguardo. Indicata per ombreggiare strutture esistenti, quali giardini d’inverno, pergolati, pompeiane, anche di grandi dimensioni.",
        "Pergola ombreggiante dal design puro e minimale, dotata di grande praticità, eleganza funzionale e resistenza. Il telo viene avvolto nel cassonetto d’alluminio e tensionato tramite molle a gas e zip laterali. Qualità BT Group, che riconosci subito al primo sguardo.Pergola con piantane frontali da 8×8 cm., che si possono arretrare no a 1 mt. in sporgenza. Si può installare a so tto o parete.",
        "Pergola ombreggiante dal design puro e minimale, dotata di grande praticità, eleganza funzionale e resistenza. Il telo viene avvolto nel cassonetto d’alluminio e tensionato tramite molle a gas e zip laterali. Qualità BT Group, che riconosci subito al primo sguardo.Pergola con piantane frontali inclinate in larice netto nodi. Si può installare a so tto o parete.",
      ],
      vele: ["r300_vela_basic", "r310_vela_cube"],
      descrizionevele: [
        "Vela ombreggiante con sistema brevettato di tensionamento del telo integrato nel montante inclinato, regolato da un automatismo con molle a gas. Montante con registro micrometrico dell’inclinazione. Tessuto in DACRON. Struttura verniciata con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Dimensioni massime realizzabili 6.00 x 6.00 mt.",
        "Vela ombreggiante con sistema brevettato di tensionamento del telo integrato nei montanti inclinati, regolato da un automatismo con molle a gas. Montanti con registro micrometrico dell’inclinazione. Tessuto poliestere. Sistema a doppio cavo per allineamento chiusura telo e di sicurezza. Dimensioni massime realizzabili 5.00 x 5.00 mt.",
      ],
    },
  ],
  chiusurePerimetrali: [
    {
      pergosky: [
        {
          nome: "r608",
          descrizione:
            "Pergola Bioclimatica Autoportante in alluminio dal design ricercato e innovativo. Sistema <b>tuttovetro scorrevole</b>, posizionato all’esterno della struttura per un gradevole effetto “House of Glass”. Gronda perimetrale da 20 x 21,5 cm. con raccolta acqua integrata nei montanti. <br></br> Copertura a lamelle in <b>alluminio</b> da L 22cm x H 4cm orientabili da 0° a 140°, complete di guarnizioni e gronde scarico acqua laterale. In base all’orientamento delle lamelle, la struttura, offre protezione totale o parziale dal sole, una perfetta climatizzazione, e un sicuro riparo dalla pioggia divenendo uno spazio da vivere sempre. <br></br> <b>Dimensioni massime</b> realizzabili in modulo unico L 4.75 x S 8.25 mt. con quattro/sei montanti perimetrali da 20 x 20 cm. Funzionamento esclusivo a motore lineare a bassa tensione.",
        },
      ],
      tendeACaduta: [
        {
          nome: "t90_t90c",
          descrizione:
            "Tenda funzionante con richiamo a molla, ad argano o motore. Possibilità di applicazione a parete o soffitto con piastre in alluminio pressofuso. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Possibilità di copertura con cassonetto per una completa protezione del tessuto. Struttura indicata per laterali su balconi e finestre. Dimensioni massime: larghezza mt. 4.00",
        },
        {
          nome: "t115",
          descrizione:
            "Tenda modulare verticale a cassonetto dal design curato e compatto particolarmente indicata per finestre, balconi e facciate continue. Cassonetto ispezionabile, predisposto per attacco a parete o soffitto. Il terminale viene guidato su cavi in acciaio ø 5 mm. installabili su davanzali, pavimenti e ringhiere.",
        },
        {
          nome: "t150max",
          descrizione:
            "Tenda modulare verticale a cassonetto dal design curato e compatto particolarmente indicata per finestre, balconi e facciate continue. Cassonetto ispezionabile, predisposto per attacco a parete o soffitto. Il terminale viene guidato su cavi in acciaio ø 5 mm. installabili su davanzali, pavimenti e ringhiere.",
        },
        {
          nome: "t900_t900c",
          descrizione:
            "Tenda funzionante con richiamo a molla, ad argano o motore, con braccetti a sporgere da 60 cm. Possibilità di applicazione a parete o soffitto con piastre in alluminio pressofuso. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita seguendo rigidi standard qualitativi. Possibilità di copertura con cassonetto per una completa protezione del tessuto. Struttura indicata per laterali su balconi e finestre. Larghezza max mt. 4.00.",
        },
        {
          nome: "t92_t92c",
          descrizione:
            "Tenda funzionante ad argano o motore, con scorrimento del telo in guide d’alluminio verticali o all’occorrenza inclinabili, installabili su davanzali, pavimenti, ringhiere o pareti. Possibilità di applicazione a parete o soffitto con piastre in alluminio pressofuso. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita seguendo rigidi standard qualitativi. Possibilità di copertura con cassonetto per una completa protezione del tessuto. Struttura indicata per laterali su balconi e finestre. Max larghezza mt. 4.50.",
        },
        {
          nome: "t94_t94c",
          descrizione:
            "Tenda funzionante ad argano o motore, con cavo d’acciaio installabile su davanzali, pavimenti, ringhiere o pareti. Possibilità di applicazione a parete o soffitto con piastre in alluminio pressofuso. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguitapresso il nostro stabilimento seguendo rigidi standard qualitativi. Possibilità di copertura con cassonetto per una completa protezione del tessuto. Struttura indicata per laterali su balconi e finestre. Larghezza max. mt. 4.00.",
        },
        {
          nome: "t39_t39c",
          descrizione:
            "Tenda funzionante ad argano o motore, con braccetti fissi a sporgere. Possibilità di applicazione a parete o soffitto con piastre in alluminio pressofuso. Viteria esposta in acciaio inox. Verniciatura con polveri poliestere eseguita seguendo rigidi standard qualitativi. Possibilità di copertura con cassonetto per una completa protezione del tessuto. Struttura indicata per finestre. Dimensioni massime: larghezza mt. 4.00, braccetti cm. 80/100. I bracci sono realizzati sia con attacco a parete che a spallina, indicare sempre il tipo di posa.",
        },
        {
          nome: "t49_t49c",
          descrizione:
            "Tenda funzionante ad argano o motore, con scorrimento del telo in guide d’alluminio verticali o all’occorrenza inclinabili. Il terminale viene guidato da una coppia di braccetti rotanti e scorrevoli. Possibilità di applicazione a parete o soffitto con piastre in alluminio pressofuso. Verniciatura con polveri poliestere eseguita seguendo rigidi standard qualitativi. Possibilità di copertura con cassonetto per una completa protezione del tessuto. Struttura indicata per laterali su balconi e finestre. Max larghezza mt. 4.50, braccetti da 60 cm.",
        },
        {
          nome: "t300",
          descrizione:
            "Tenda a caduta, dal design ricercato, con doppio rullo avvolgitore, per telo estivo e invernale. Telo invernale semitrasparente inserito nella doppia guida dotata di doppio spazzolino per riparo dal vento. Supporti e snodi in alluminio pressofuso, garantiscono un’ottima resistenza e qualità estetica. Profilo terminale rinforzato, con chiavistelli, permette una perfetta tensione del telo. Guarnizione in PVC morbido, adatta agli eventuali dislivelli del pavimento. Braccetti fissi a sporgere da 115 cm. con fermi automatici ed optional su richiesta 75 o 95 cm. La struttura può essere dotata dei seguenti optional: cassonetto esterno con viti e spazzolino, cassonetto interno con viti spazzolino e piastre, frangitratta intemedio per telo invernale. Optional: 2 motori radio con finecorsa elettronico e riconoscimento degli ostacoli, con telecomando.",
        },
        {
          nome: "t400",
          descrizione:
            "Tenda a caduta, dal design ricercato, con doppio rullo avvolgitore, per telo estivo e invernale. Telo invernale semitrasparente inserito nella doppia guida dotata di doppio spazzolino per riparo dal vento. Supporti e snodi in alluminio pressofuso, garantiscono un’ottima resistenza e qualità estetica. Profilo terminale rinforzato, con chiavistelli, permette una perfetta tensione del telo. Guarnizione in PVC morbido, adatta agli eventuali dislivelli del pavimento. Braccetti a sporgere da 75 cm. con frangivento intermedio e fermi automatici. La struttura può essere dotata dei seguenti optional: cassonetto esterno con viti e spazzolino, cassonetto interno con viti e spazzolino e piastre, frangitratta intemedio per telo invernale. Optional: 2 motori radio con finecorsa elettronico e riconoscimento degli ostacoli, con telecomando.",
        },
        {
          nome: "t306_302",
          descrizione:
            "Tenda a caduta, dal design ricercato, con doppio rullo avvolgitore, per telo estivo e invernale. Telo invernale semitrasparente inserito nella doppia guida dotata di doppio spazzolino per riparo dal vento. Supporti e snodi in alluminio pressofuso, garantiscono un’ottima resistenza e qualità estetica. Profilo terminale rinforzato, con chiavistelli, permette una perfetta tensione del telo. Guarnizione in PVC morbido, adatta agli eventuali dislivelli del pavimento. Protezione solare a caduta verticale senza braccetti. La struttura può essere dotata dei seguenti optional: cassonetto esterno con viti e spazzolino, cassonetto interno con viti spazzolino e piastre, frangitratta intemedio per telo invernale. Optional: 2 motori radio con finecorsa elettronico e riconoscimento degli ostacoli, con telecomando.",
        },
        {
          nome: "t31",
          descrizione:
            " Tenda a copertura verticale con min. ingombro funzionante a catenella con braccetti rotanti. Profilialluminio e particolari in resine termoplastiche. Teloraccolto racchiuso completamente nel cassonetto. Applicazione anche a parete o soffitto. Verniciatura con polveri poliestere seguendo rigidi standard qualitativi. Optional applicato in spallina: Zanzariera funzionante a catenella o molla di richiamo, di minimo ingombro e applicabile ad incastro. Larghezza min. 75 cm. N.B. L’azionamento a catenella della zanzariera SPRING va installato sul lato opposto a quello della tenda.",
        },
        {
          nome: "t24_max",
          descrizione:
            "Tenda modulare di tipo verticale a cassonetto dal design curato e compatto particolarmente indicata per finestre, balconi e facciate continue. Azionamento manuale ad argano con possibilità di motorizzazione. Il terminale viene guidato da apposite guide in cavo in acciaio ø 5 mm. installabili su davanzali, pavimenti ringhiere o pareti. Possibilità di applicazione a soffitto o parete.",
        },
        {
          nome: "t96",
          descrizione:
            "Tenda a caduta verticale con cassonetto, dotata di guide antivento con fissaggio a pavimento. Sistema di scorrimento del telo all’interno delle guide tramite frangivento in alluminio. Azionamento manuale ad argano o motore. Catenaccioli inox al terminale inclusi. Fissaggio cassonetto e guide in spallina, predisposizione per fissaggio guide a pavimento. OPTIONAL: piastre a soffitto per cassonetto. Viteria esposta in acciaio inox. Larghezza max cm. 400 per un’altezza max di 310 cm. Cassonetto dotato di sedi superiori per triangolo in PVC o policarbonato e sede inferiore per drappeggi.",
        },
        {
          nome: "t33",
          descrizione:
            "Tenda con bracci rotanti realizzata in ferro battuto forgiato a mano, trattato con zincatura a caldo e verniciato con polveri poliestere secondo rigidi standard qualitativi in tinta grigio ferro micaceo. Tettuccio copritelo in alluminio anch’esso verniciato in tinta grigio ferro micaceo. Telo tinta unita o con decoro. L’eleganza dello stile antico, vuol donare il sapore del passato rivisitato in chiave moderna. Ideale per coprire vetrine e finestre di centri storici o borghi antichi.",
        },
        {
          nome: "t37",
          descrizione:
            "Struttura realizzata interamente in acciaio inox con bracci rotanti e terminale in alluminio verniciato. Design moderno, raffinato, curato nei minimi dettagli. Ideale per coprire vetrine e finestre di negozi, ad alto impatto scenico. Telo tinta unita o con decoro realizzato con tasca intermedia interna MOD. 6. Versione: attacco a parete o spallina. OPTIONAL: tettuccio copritelo.",
        },
        {
          nome: "z150zip_max",
          descrizione:
            "Tenda modulare verticale a cassonetto dal design curato e compatto particolarmente indicata per finestre, balconi e facciate continue. Cassonetto ispezionabile,predisposto per attacco a parete o soffitto. Il terminale viene guidato su cavi in acciaio ø 5 mm. installabili su davanzali, pavimenti e ringhiere. Dimensioni massime: larghezza 600 x 300 cm di altezza",
        },
        {
          nome: "z115",
          descrizione:
            "Tenda modulare verticale a cassonetto dal design curato e compatto particolarmente indicata per finestre, balconi e facciate continue. Cassonetto ispezionabile,predisposto per attacco a parete o soffitto. Il terminale viene guidato su cavi in acciaio ø 5 mm. installabili su davanzali, pavimenti e ringhiere. Dimensioni massime: larghezza 600 x 300 cm di altezza ",
        },
      ],
    },
  ],
  frangisole: [
    {
      f20: [
        {
          nome: "f20_orizzontali",
          descrizione: "<p>150×270 cm. <br></br> 100×290 cm.</p>",
        },
        {
          nome: "f20_verticali",
          descrizione: "145×270 cm. <br></br> 95×290 cm.",
        },
      ],
      f30: [
        {
          nome: "f30",
          descrizione: "Misure massime: 150×270 cm. <br></br> 100×290 cm",
        },
      ],
      grigliato: [
        {
          nome: "g80",
          descrizione: "150 x 270 cm.",
        },
      ],
      pedane: [
        {
          nome: "r590",
          descrizione: "Modulare",
        },
      ],
    },
  ],
  vele: [
    {
      nome: "r300_vela_basic",
      descrizione:
        "Vela ombreggiante con sistema brevettato di tensionamento del telo integrato nel montante inclinato, regolato da un automatismo con molle a gas. <br></br> Montante con registro micrometrico dell’inclinazione. Tessuto in DACRON. Struttura verniciata con polveri poliestere eseguita presso il nostro stabilimento seguendo rigidi standard qualitativi. Dimensioni massime realizzabili 6.00 x 6.00 mt.",
    },
    {
      nome: "r310_vela_cube",
      descrizione:
        "Vela ombreggiante con sistema brevettato di tensionamento del telo integrato nei montanti inclinati, regolato da un automatismo con molle a gas. Montanti con registro micrometrico dell’inclinazione. Tessuto poliestere. Sistema a doppio cavo per allineamento chiusura telo e di sicurezza. Dimensioni massime realizzabili 5.00 x 5.00 mt.",
    },
    {
      nome: "r320_vela_double",
      descrizione:
        "Vela ombreggiante dalla struttura autoportante su quattro appoggi a terra, elegante e minimale che ben esprime le sensazioni di freschezza e desiderio di luce. Possibilità di attacco del rullo a parete con apposita piastra angolare. Sistema brevettato di tensionamento del telo integrato nei montanti inclinati. Montanti con registro micrometrico dell’inclinazione. Tessuto in DACRON. Dimensioni massime realizzabile doppio telo da 6.00 x 12.00 mt. cad.",
    },
  ],
  lavori: [
    "lavoro1",
    "lavoro2",
    "lavoro3",
    "lavoro4",
    "lavoro5",
    "lavoro6",
    "lavoro7",
    "lavoro8",
    "lavoro9",
    "lavoro10",
    "lavoro11",
    "lavoro12",
    "lavoro13",
    "lavoro14",
    "lavoro15",
    "lavoro16",
    "lavoro17",
  ],
};

export default data;
