import React from "react";
import { Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const bucketUrl = "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/";

export default function ProdottoItem({ prodotto, handleDetail, vertical }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <Grid container spacing={3} style={{ marginTop: "1rem" }}>
      {prodotto.map((element, index) => {
        return (
          <Grid
            item
            xs={6}
            md={3}
            className="center"
            style={{ cursor: "pointer" }}
            key={index}
          >
            <div
              onClick={() =>
                handleDetail(
                  element.split("/")[2].split(".")[0],
                  `${bucketUrl}${element}`
                )
              }
            >
              <img
                src={`${bucketUrl}${element}`}
                alt={element.name}
                className={
                  isTabletOrMobile
                    ? "product-img-item-mobile"
                    : "product-img-item"
                }
                style={{ height: vertical ? "400px" : "200px" }}
              />
              <div className="center">
                <h3 style={{ textTransform: "uppercase" }}>
                  {element.split("/")[2].split(".")[0].replaceAll("_", " ")}
                </h3>
              </div>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
