import React from "react";
import Navbar from "../components/Navbar";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "./NavbarMobile";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

export default function Agevolazioni() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <div style={{ padding: isTabletOrMobile ? "0" : "1rem 3rem" }}>
      {isTabletOrMobile ? <NavbarMobile /> : <Navbar />}
      <div className="agevolazioni-container">
        <h4 className="agevolazioni-subtitle">
          vademecum operativo - MARZO 2022
        </h4>
        <div style={{ padding: "0 0 2rem 0" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#61CE70", padding: "1.2rem" }}
          >
            <div className="center">
              <DownloadIcon style={{ marginRight: "0.5rem" }} />
              <a
                href="./Ecobonus22.pdf"
                download
                style={{ textDecoration: "none", color: "white" }}
              >
                Scarica la guida alla detrazione fiscale
              </a>
            </div>
          </Button>
        </div>
        <h6 className="agevolazioni-sub-subtitle">
          SCHERMATURE SOLARI E CHIUSURE OSCURANTI
        </h6>
        <p>
          E’ agevolabile l’acquisto e posa in opera di schermature solari e/o
          chiusure tecniche mobili oscuranti (ai sensi del D.M. 6.08.2020)
          montati in modo solidale all’involucro edilizio o ai suoi componenti e
          installati all’interno, all’esterno o integrati alla superficie
          finestrata.
        </p>
        <h4 className="agevolazioni-title">CHI PUÒ ACCEDERE:</h4>
        <p>tutti i contribuenti che:</p>
        <span>
          <ul>
            <li>sostengono le spese di riqualificazione energetica;</li>
            <li>
              posseggono un diritto reale sulle unità immobiliari costituenti
              l’edificio;
            </li>
          </ul>
        </span>
        <p>
          E’ possibile per tutti i contribuenti, in luogo delle detrazioni,
          optare per la cessione del credito (1).
        </p>
        <h4 className="agevolazioni-title">PER QUALI EDIFICI:</h4>
        <p>
          alla data della richiesta di detrazione, devono essere “esistenti”
          ossia accatastati o con richiesta di accatastamento in corso e in
          regola con il pagamento di eventuali tributi;
        </p>
        <h4 className="agevolazioni-title">ENTITA’ DEL BENEFICIO:</h4>
        <p>
          è possibile detrarre il 50% delle spese totali sostenute dal 1.1.2022
          al 31.12.2022, per un massimo di 60.000 € per unità immobiliare (2).
          Dal 16 Aprile 2022 massimale di € 276,00 mq al netto di Iva,
          prestazioni professionali e opere complementari relative
          all’installazione.
        </p>

        <h4 className="agevolazioni-title">
          REQUISITI TECNICI SPECIFICI DELL’INTERVENTO:
        </h4>
        <ul>
          <li>
            è agevolabile l’installazione di sistemi di schermatura di cui
            all’Allegato M (*) al DLgs 311 del 29/12/2006;
          </li>
          <li>
            devono essere applicate in modo solidale con l’involucro edilizio e
            non liberamente montabili e smontabili dall’utente;
          </li>
          <li>devono essere a protezione di una superficie vetrata;</li>
          <li>
            possono essere installate all’interno, all’esterno o integrate alla
            superficie vetrata;
          </li>
          <li>devono essere mobili;</li>
          <li>devono essere schermature “tecniche”;</li>
          <li>
            le chiusure oscuranti possono essere in combinazioni con vetrate o
            autonome (aggettanti);
          </li>
        </ul>
        <p>
          (1) Per maggiori approfondimenti si rimanda al “Provvedimento
          dell’Agenzia delle Entrate del 28.08.2017”
        </p>
        <p>
          (2) Se l’intervento è eseguito contestualmente a un altro intervento
          sull’involucro (coibentazione e/o schermature solari, la detrazione
          massima complessiva rimane di €60.000.
        </p>
        <h4 className="agevolazioni-title">
          REQUISITI TECNICI SPECIFICI DELL’INTERVENTO:
        </h4>
        <ul>
          <li>
            {" "}
            è agevolabile l’installazione di sistemi di schermatura di cui
            all’Allegato M (*) al DLgs 311 del 29/12/2006;
          </li>
          <li>
            devono essere applicate in modo solidale con l’involucro edilizio e
            non liberamente montabili e smontabili dall’utente;
          </li>
          <li>devono essere a protezione di una superficie vetrata;</li>
          <li>
            possono essere installate all’interno, all’esterno o integrate alla
            superficie vetrata;
          </li>
          <li>devono essere mobili;</li>
          <li>devono essere schermature “tecniche”;</li>
          <li>
            le chiusure oscuranti possono essere in combinazioni con vetrate o
            autonome (aggettanti);
          </li>
          <li>
            per le chiusure oscuranti sono ammessi tutti gli orientamenti;
          </li>
          <li>
            per le schermature solari sono ammesse le esposizioni da Est (E) a
            Ovest (O), passando per il Sud (S).
          </li>
          <li>devono possedere un valore di gtot ≤0,35;</li>
          <li>devono possedere una marcatura CE, se prevista;</li>
          <li>
            devono rispettare le leggi e normative nazionali e locali in tema di
            sicurezza e di efficienza energetica;
          </li>
        </ul>
        <h4 className="agevolazioni-title">PROCEDURA OPERATIVA RIVENDITORE:</h4>
        <ul>
          <li>nella fattura di saldo verranno indicati i seguenti elementi:</li>
          <li>nome del prodotto e suo tipo</li>
          <li>dichiarazione che il medesimo è conforme alla norma EN13561</li>
          <li>unità di misura e relativi metri quadri della schermatura</li>
          <li>costo del prodotto e costo della posa</li>
          <li>
            gtot relativo ad ogni singola schermatura solare (classe di
            prestazione energetica) secondo la norma EN 14501
          </li>
          <li>
            risparmio energetico stimato (kWh) inserire il valore 0 (zero)
          </li>
          <li>Rilasciare le schede tecniche di prodotto;</li>
          <li>
            Rilasciare il manuale d’uso e manutenzione ed etichettatura CE.
          </li>
        </ul>
        <h4 className="agevolazioni-title">PROCEDURA OPERATIVA CLIENTE:</h4>
        <ul>
          <li>
            Pagamento con bonifico bancario “parlante” per risparmio energetico
            L.296-06 e seguenti;
          </li>
          <li>
            Registrazione presso il portale ENEA ( https://bonusfiscali.enea.it
            ) entro 90 gg dalla data di fine lavori, preparando i seguenti dati
            utili per la compilazione delle varie opzioni:
          </li>
          <li>
            dati tecnici del prodotto acquistato presenti nella fattura di saldo
          </li>
          <li>dati fiscali del contribuente</li>
          <li>
            dati catastali dell’immobile su cui sono state installate le
            schermature solari
          </li>
          <li>orientamento della tenda ai punti cardinali</li>
          <li>mq totali di superficie vetrata schermata</li>
        </ul>
        <p>
          Tutti i dati sopra richiesti, sono in possesso del cliente che si
          registra sul sito internet di Enea e compila la pratica; di
          conseguenza Brianzatende non ne è a conoscenza / in possesso.{" "}
          <br></br>
          <b>Dal 1° ottobre 2021</b> per inserire la pratica sul sito ENEA{" "}
          <b>sarà obbligatorio autenticarsi con lo SPID.</b> Per richiedere lo
          SPID consultare il sito https://www.spid.gov.it/ o recarsi agli
          sportelli postali abilitati. <br></br>
          Al termine si ottiene ricevuta della pratica da allegare alla
          richiesta di detrazione da inserire nella loro dichiarazione dei
          redditi. In particolare il Contribuente dovrà aprire la propria
          richiesta e la propria scheda e in tal senso lo aiuterà il software
          preparato da ENEA. <br></br>
          La prima opzione è dedicata alla compilazione dei dati anagrafici del
          Richiedente le detrazioni. Viene poi la scheda da completare con i
          dati dell’immobile su cui si è operato con la posa delle schermature
          solari. <br></br>
          Si dovrà ora scegliere l’intervento su cui si chiedono le detrazioni.
          <br></br>
          Per le Schermature si scelga il codice SS indicato.
          <br></br>
          Ora la procedura chiede informazioni dirette sulle schermature. Si
          dovrà quindi aggiungere un nuovo gruppo di coperture solari: <br></br>
          Così da aprire nuove opzioni Con i dati raccolti e preparati sarà
          possibile completare e salvare le diverse tende inserite con
          semplicità. <br></br>
          Si faccia particolare attenzione all’allegato F che relativamente al
          risparmio energetico stimato permette l’inserimento di un valore “0”.{" "}
          <br></br>
          TOTALE AL NETTO DELLE SPESE PROFESSIONALI sono intese come le spese
          che il cliente affronta nel momento in cui si avvale di uno studio di
          architettura o di un professionista per il progetto, e questa spesa,
          se presente, viene riportata in fattura. <br></br>I documenti da
          conservare da parte del cliente sono la fattura, la copia del bonifico
          bancario parlante effettuato, il manuale CE della tenda ed il codice
          CPID indicato al termine della registrazione sul portale ENEA, che
          rappresenta la ricevuta di effettivo invio della documentazione a
          ENEA.
        </p>
        <div style={{ borderLeft: "10px solid grey", paddingLeft: "2rem" }}>
          <p>
            <b>
              <i>
                I documenti da conservare da parte del cliente sono la fattura,
                la ricevuta di pagamento o bonifico, il manuale CE della tenda
                ed il codice CPID indicato al termine della registrazione sul
                portale ENEA, che rappresenta la ricevuta di effettivo invio
                della documentazione a ENEA.
              </i>
            </b>
          </p>
        </div>
      </div>
    </div>
  );
}
