import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import aws from "aws-sdk";
import data from "../../data";
import ProdottoDetailDialog from "./ProdottoDetailDialog";
import ProdottoItem from "./ProdottoItem";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../NavbarMobile";

const ACCESSKEYID = "AKIA6O3BXFU5B2H3SBPC";
const SECRETKEY =  "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";
const BUCKETNAME = "crystal-tende-bucket";

aws.config.update({ region: "eu-central-1" });
const s3 = new aws.S3({ accessKeyId: ACCESSKEYID, secretAccessKey: SECRETKEY });

var bucketParamsTende = {
  Bucket: BUCKETNAME,
  Prefix: "tendeDaEsterno/tendeABracci",
};

export default function TendeDaEsterno() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [tendeABracci, setTendeABracci] = useState([]);
  const [capottine, setCapottine] = useState([]);
  const [guideLaterali, setGuideLaterali] = useState([]);
  const [gazebi, setGazebi] = useState([]);
  const [openDetailTende, setOpenDetailTende] = useState(false);
  const [openDetailCapottine, setOpenDetailCapottine] = useState(false);
  const [openDetailGuide, setOpenDetailGuide] = useState(false);
  const [openDetailGazebi, setOpenDetailGazebi] = useState(false);

  const [tende, setTende] = useState("");
  const [capottinaItem, setCapottinaItem] = useState("");
  const [guideItem, setGuideItem] = useState("");
  const [gazebiItem, setGazebiItem] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    s3.listObjects(bucketParamsTende, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setTendeABracci(keyArray);
      }
    });

    var bucketParamsCapottine = {
      Bucket: BUCKETNAME,
      Prefix: "tendeDaEsterno/capottine",
    };

    s3.listObjects(bucketParamsCapottine, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setCapottine(keyArray);
      }
    });

    var bucketGuideLaterali = {
      Bucket: BUCKETNAME,
      Prefix: "tendeDaEsterno/guideLaterali",
    };

    s3.listObjects(bucketGuideLaterali, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setGuideLaterali(keyArray);
      }
    });

    var bucketGazebi = {
      Bucket: BUCKETNAME,
      Prefix: "tendeDaEsterno/gazebi",
    };

    s3.listObjects(bucketGazebi, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setGazebi(keyArray);
      }
    });
  }, []);

  const handleItemDetail = (name, img) => {
    var description;
    for (var i = 0; i < data.tendeDaEsterno[0].tendeABracci.length; i++) {
      if (data.tendeDaEsterno[0].tendeABracci[i] === name) {
        description = data.tendeDaEsterno[0].descrizionetendeABracci[i];
      }
    }
    setTende({
      img,
      name: name.replaceAll("_", " "),
      description,
    });
    setOpenDetailTende(true);
  };

  const handleCapottineDetail = (name, img) => {
    var description;
    for (var i = 0; i < data.tendeDaEsterno[0].cappottine.length; i++) {
      if (data.tendeDaEsterno[0].cappottine[i] === name) {
        description = data.tendeDaEsterno[0].descrizionecappottine[i];
      }
    }
    setCapottinaItem({
      img,
      name: name.replaceAll("_", " "),
      description,
    });
    setOpenDetailCapottine(true);
  };

  const handleGuideDetail = (name, img) => {
    data.tendeDaEsterno[0].guideLaterali.forEach((element) => {
      if (element.nome === name) {
        setGuideItem({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailGuide(true);
  };

  const handleGazebiDetail = (name, img) => {
    data.tendeDaEsterno[0].gazebi.forEach((element) => {
      if (element.nome === name) {
        setGazebiItem({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailGazebi(true);
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "1rem 1rem 5rem 1rem",
      }}
    >
      {isTabletOrMobile ? <NavbarMobile /> : <Navbar />}
      <div className="center" style={{ width: "100%", marginTop: "2rem" }}>
        <h1 className="product-title">Tende da Esterno</h1>
      </div>
      <hr
        className={
          isTabletOrMobile ? "product-title-hr-mobile" : "product-title-hr"
        }
      ></hr>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Tende a bracci</h2>
      </div>
      <ProdottoItem prodotto={tendeABracci} handleDetail={handleItemDetail} />
      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Capottine</h2>
      </div>
      <ProdottoItem prodotto={capottine} handleDetail={handleCapottineDetail} />
      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Guide Laterali</h2>
      </div>
      <ProdottoItem prodotto={guideLaterali} handleDetail={handleGuideDetail} />
      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>

      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Gazebi</h2>
      </div>
      <ProdottoItem prodotto={gazebi} handleDetail={handleGazebiDetail} />

      <ProdottoDetailDialog
        open={openDetailTende}
        setOpen={setOpenDetailTende}
        prodotto={tende}
      />

      <ProdottoDetailDialog
        open={openDetailCapottine}
        setOpen={setOpenDetailCapottine}
        prodotto={capottinaItem}
      />

      <ProdottoDetailDialog
        open={openDetailGuide}
        setOpen={setOpenDetailGuide}
        prodotto={guideItem}
      />

      <ProdottoDetailDialog
        open={openDetailGazebi}
        setOpen={setOpenDetailGazebi}
        prodotto={gazebiItem}
      />
    </div>
  );
}
