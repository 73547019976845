import { Box, Grid, SwipeableDrawer, Card, Typography } from "@mui/material";
import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Link } from "react-router-dom";

export default function HomeMobile() {
  const [openMenu, setOpenMenu] = useState(false);

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const images = [
    {
      label: "1",
      imgPath:
        "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola1.jpg",
    },
    {
      label: "2",
      imgPath:
        "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola2.jpg",
    },
    {
      label: "3",
      imgPath:
        "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola3.jpg",
    },
    {
      label: "4",
      imgPath:
        "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/lavori/lavoro125.jpeg",
    },
    {
      label: "5",
      imgPath:
        "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola5.jpg",
    },
    {
      label: "6",
      imgPath:
        "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pergola6.jpg",
    },
  ];

  return (
    <div>
      <section>
        <div style={{ position: "relative" }}>
          <img
            src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/bghome.jpg"
            alt="3"
            style={{ width: "100%", height: "90vh", zIndex: "-100" }}
          />
          <nav className="nav-mobile">
            <div style={{ width: "90%" }}>
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/logo_crystal.jpg"
                alt="logo_crystal_tende"
                style={{ width: "70px", height: "50px" }}
              />
            </div>
            <Box style={{ zIndex: "100" }}>
              <button
                aria-label="Main Menu"
                onClick={() => setOpenMenu(!openMenu)}
                className={openMenu ? "menu opened" : "menu"}
              >
                <svg width="50" height="50" viewBox="0 0 100 100">
                  <path
                    className="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path className="line line2" d="M 20,50 H 80" />
                  <path
                    className="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </button>
            </Box>
            <SwipeableDrawer
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              style={{ zIndex: "90" }}
              anchor="right"
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              onOpen={() => setOpenMenu(true)}
            >
              <Box className="nav-mobile-container">
                <Grid container style={{ height: "50%" }}>
                  <Grid item xs={12} className="center">
                    <div className="center drawer-box-item">
                      <h1 className="drawer-items">
                        <Link to="/" className="link">
                          Home
                        </Link>
                      </h1>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="center">
                    <div className="center drawer-box-item">
                      <h1 className="drawer-items">
                        <Link to="/prodotti" className="link">
                          Prodotti
                        </Link>
                      </h1>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="center">
                    <div className="center drawer-box-item">
                      <h1 className="drawer-items">
                        <Link to="/agevolazioni" className="link">
                          Agevolazioni
                        </Link>
                      </h1>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="center">
                    <div className="center drawer-box-item">
                      <h1 className="drawer-items">
                        <Link to="/installazioni" className="link">
                          Installazioni
                        </Link>
                      </h1>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="center">
                    <div className="center drawer-box-item">
                      <h1 className="drawer-items">
                        <Link to="/contatti" className="link">
                          Contatti
                        </Link>
                      </h1>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </SwipeableDrawer>
          </nav>
          <div className="center card-container-mobile">
            <Card
              variant="outlined"
              className="card"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <h1 className="ecobonus-mobile">
                Grazie all'{" "}
                <span style={{ color: "lightgreen" }}> ECOBONUS </span>
                statale puoi recuperare il
                <span style={{ color: "lightgreen" }}> 50% </span>
                della spesa!
              </h1>
              <h1 className="showroom-mobile">Showroom in allestimento</h1>
            </Card>
          </div>
        </div>
      </section>
      <section className="section-three-mobile">
        <div className="center">
          <h1 className="section-three-title-mobile">
            Esperienza pluridecennale
          </h1>
        </div>
        <Grid container style={{ marginTop: "4rem" }}>
          <Grid
            item
            xs={12}
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className="center"
          >
            <div className="section-three-img-mobile">
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/tenda1.jpg"
                alt="tenda1"
                style={{
                  width: "300px",
                  height: "225px",
                  transform: "translateX(15px) translateY(15px)",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} className="center" style={{ textAlign: "center" }}>
            <h3
              className="description-mobile"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              Offriamo un servizio che parte dal sopralluogo al punto di
              installazione con particolare attenzione alle richieste del
              cliente.
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            className="center"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <div
              className="section-three-img-mobile"
              style={{ marginTop: "8rem" }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/tenda2.jpg"
                alt="tenda1"
                style={{
                  width: "300px",
                  height: "225px",
                  transform: "translateX(-15px) translateY(15px)",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} className="center" style={{ textAlign: "center" }}>
            <h3
              className="description-mobile"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              Garantiamo assistenza anche nel post-installazione trovando sempre
              il modo di soddisfare al meglio le esigenze in termini di estetica
              e funzionalit&agrave;.
            </h3>
          </Grid>
          <Grid item xs={12}>
            <hr className="hr-mobile"></hr>
          </Grid>
          <Grid
            item
            xs={12}
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            style={{ paddingTop: "2rem" }}
          >
            <div className="center" style={{ textAlign: "center" }}>
              <h3 style={{ fontSize: "2rem" }} data-aos="fade-up">
                Portiamo al cliente il massimo della professionalit&agrave; e
                della qualit&agrave;, accompagnata da una vasta gamma di
                prodotti personalizzabili per ogni situazione.
              </h3>
            </div>
            <Splide options={{ autoplay: "true" }}>
              {images.map((image) => {
                return (
                  <SplideSlide className="center" key={image.label}>
                    <img
                      src={image.imgPath}
                      alt={image.label}
                      className="carousel-splide-img"
                    />
                  </SplideSlide>
                );
              })}
            </Splide>
            <div className="center">
              <h2>
                <Link to="/prodotti" style={{ color: "black" }}>
                  Guarda altri lavori
                </Link>
              </h2>
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", padding: "4rem 0" }}>
            <h2 style={{ fontSize: "2.5rem" }}>Code</h2>
            <Link to="/prodotti/code" className="link">
              <div className="center">
                <img
                  src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/code-home-page.jpeg"
                  alt="code-home-page"
                  height="565px"
                  width="800px"
                  style={{ border: "2px solid black" }}
                />
              </div>
            </Link>
          </Grid>
        </Grid>
      </section>
      <section className="section-two-mobile">
        <div className="center" style={{ padding: "0 2rem" }}>
          <h1 className="section-two-header-mobile">
            <b>Perch&egrave; sceglierci</b>
          </h1>
        </div>
        <Grid container>
          <Grid item xs={12} style={{ padding: "0 2rem" }}>
            <h2 className="title-item-mobile">
              Assicurazione <br></br> 10 anni su
              <br></br>responsabilit&agrave; civile
            </h2>
            <div style={{ margin: "2rem 0" }}></div>
            <Typography variant="subtitle1" className="subtitle-item">
              Si tratta di un'assicurazione per danni causati a cose o persone
              derivanti da ben accertate cause relative a difetti del prodotto.
            </Typography>
            <div
              className="center"
              style={{
                marginTop: "4rem",
              }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/insurance.png"
                alt="insurance"
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ padding: "2rem" }}>
            <div style={{ textAlign: "right" }}>
              <h2 className="title-item-mobile"> 100% Made in Italy</h2>
            </div>
            <div style={{ margin: "2rem 0" }}></div>
            <Typography
              variant="subtitle1"
              className="subtitle-item"
              style={{ textAlign: "right" }}
            >
              I prodotti sono esclusivamente <br></br> Made in Italy,
              espressione di qualità, stile e prestigio.
            </Typography>
            <div
              className="center"
              style={{
                marginTop: "4rem",
              }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/italia.jpg"
                alt="italia"
                style={{ width: "300px", height: "180px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ padding: "2rem" }}>
            <h2 className="title-item-mobile"> Marchi CE</h2>
            <div style={{ margin: "2rem 0" }}></div>
            <Typography variant="subtitle1" className="subtitle-item">
              I prodotti vengono progettati e realizzati nel rispetto della
              Normativa UNI EN 13561 con CE.
            </Typography>
            <div
              className="center"
              style={{
                marginTop: "4rem",
              }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/ce.jpg"
                alt="CE"
                style={{ width: "300px", height: "200px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ padding: "2rem" }}>
            <h2 className="title-item-mobile" style={{ textAlign: "right" }}>
              {" "}
              <b>Resistenza al vento, sole e pioggia</b>
            </h2>
            <div style={{ margin: "2rem 0" }}></div>
            <Typography
              variant="subtitle1"
              className="subtitle-item"
              style={{ textAlign: "right" }}
            >
              I prodotti sono di grande resistenza meccanica alle sollecitazioni
              del vento e specifici per la protezione dal sole e dalla pioggia.
            </Typography>
            <div
              className="center"
              style={{
                marginTop: "4rem",
              }}
            >
              <img
                src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/windy.png"
                alt="windy"
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          </Grid>
        </Grid>
        <div
          className="aiuti-stato-container"
          style={{ paddingBottom: "3rem " }}
        >
          <h1>
            La ditta ha ricevuto nell'anno 2020 benefici rientranti nel regime
            degli aiuti di Stato o regime de minimis per i quali sussiste
            l'obbligo di pubblicazione nel Registro Nazionale degli aiuti di
            Stato di cui all'art. 52 della L. 234/2012.
          </h1>
        </div>
      </section>
    </div>
  );
}
