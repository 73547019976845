import React from "react";
import { Grid, Typography } from "@mui/material";
export default function FooterMobile() {
  return (
    <div className="footer-container-mobile">
      <Grid container className="footer-mobile">
        <Grid item xs={12} className="center">
          <img
            src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/logo_crystal.jpg"
            alt="logo"
            style={{
              width: "80px",
              height: "60px",
            }}
          />
        </Grid>

        <Grid item xs={12} className="contacts-mobile ">
          <div
            className="center"
            style={{ textAlign: "center", fontSize: "1rem" }}
          >
            <h3>Contattaci</h3>
          </div>
          <div className="center" style={{ fontSize: "1rem" }}>
            Mail: info@crystaltende.com
          </div>
          <div
            className="center"
            style={{
              fontSize: "1rem",
            }}
          >
            PEC: manuel.loscalzo@pec.it
          </div>
          <div className="center" style={{ fontSize: "1rem" }}>
            Cellulare: +39 3480552778
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className="center"
          style={{
            textAlign: "center",
            paddingTop: "1rem",
          }}
        >
          <Typography variant="subtitle2" style={{ fontSize: "0.8rem" }}>
            © {new Date().getFullYear()} Tutti i diritti sono riservati -
            Crystal Tende
            <br></br>
            Partita IVA e Registro Imprese di Bologna: 03784831202.
            <br></br>
            <span>Sede legale: Via Calvart</span>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
