import { Card } from "@mui/material";
import React from "react";
import Navbar from "./Navbar";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "./NavbarMobile";

export default function Contatti() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      {isTabletOrMobile ? (
        <>
          <div>
            <NavbarMobile />
            <div className="contact-container">
              <h1>Showroom in allestimento</h1>
              <h1>Contattaci</h1>
              <Card elevation={3} className="info-container">
                <h1>Chiamare per appuntamento</h1>
                <h2 style={{ fontSize: "1rem" }}>
                  Mail: &nbsp;
                  <a href="mailto:info@crystaltende.com">
                    info@crystaltende.com
                  </a>
                </h2>
                <h2 style={{ fontSize: "1rem" }}>
                  PEC: &nbsp;
                  <a href="mailto:manuel.loscalzo@pec.it">
                    manuel.loscalzo@pec.it
                  </a>
                </h2>
                <h2 style={{ fontSize: "1rem" }}>
                  Cellulare: &nbsp;
                  <a href="tel:+393480552778">+39 3480552778</a>{" "}
                </h2>
              </Card>
            </div>
          </div>
        </>
      ) : (
        <div style={{ padding: "1rem 3rem" }}>
          <Navbar />
          <div className="contact-container">
            <h1>Showroom in allestimento</h1>
            <h1>Contattaci</h1>
            <Card elevation={3} className="info-container">
              <h1>Chiamare per appuntamento</h1>
              <h2>
                Mail: &nbsp;
                <a href="mailto:info@crystaltende.com">info@crystaltende.com</a>
              </h2>
              <h2>
                PEC: &nbsp;
                <a href="mailto:manuel.loscalzo@pec.it">
                  manuel.loscalzo@pec.it
                </a>
              </h2>
              <h2>
                Cellulare: &nbsp;
                <a href="tel:+393480552778">+39 3480552778</a>{" "}
              </h2>
            </Card>
          </div>
        </div>
      )}
    </>
  );
}
