import { Grid, Button } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../NavbarMobile";

const images = [
  {
    label: "1",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pedane/cumaru1.jpg",
  },
  {
    label: "2",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pedane/cumaru2.jpg",
  },
  {
    label: "3",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pedane/cumaru3.jpg",
  },
  {
    label: "5",
    imgPath:
      "https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/pedane/cumaru5.jpg",
  },
];

export default function Pavimenti() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isTabletOrMobile ? (
        <>
          <NavbarMobile />
          <div className="pedane-home" style={{ padding: "3rem 0" }}>
            <div className="pavimenti-container-mobile">
              <h1 className="product-title" style={{ color: "black" }}>
                Pavimenti
              </h1>
              <div>
                <p className="pavimenti-paragraph">
                  Versatilità, tecnologia ed estetica, questi i punti forti
                  delle chiusure per balconi e di tutti i complementi
                  CrystalTende, elementi importanti per integrare e rendere
                  funzionale lo spazio outdoor.
                </p>
                <br></br>
                <p className="pavimenti-paragraph">
                  Se la scelta della pavimentazione per interni si basa
                  maggiormente su canoni estetici, quella di una pedana da
                  giardino o cortile, invece, deve essere guidata soprattutto da
                  criteri tecnici e funzionali. Infatti, tutto ciò che fa parte
                  del vostro outdoor, è sottoposto all’azione di agenti
                  atmosferici, graffi o abrasioni che possono limitarne le
                  potenzialità o accelerarne l’usura, se non si fa un’attenta
                  valutazione.
                </p>
              </div>
            </div>
          </div>
          <div className="pedane-bottom" style={{ padding: "2rem" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h1 className="product-title">Pedane modulari</h1>
                <p style={{ fontSize: "1.2rem" }}>
                  Si tratta di una pedana modulare realizzata in doghe di legno
                  Cumarù e composta da listoni pre-assemblati di larghezza 10 cm
                  e spessore 2 cm.
                </p>
              </Grid>
              <Grid item xs={12}>
                <Splide options={{ autoplay: "true" }}>
                  {images.map((image) => {
                    return (
                      <SplideSlide className="center" key={image.label}>
                        <img
                          src={image.imgPath}
                          alt={image.label}
                          className="pavimenti-splide-mobile"
                        />
                      </SplideSlide>
                    );
                  })}
                </Splide>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <nav className="nav-container pavimenti-nav-desktop">
            <div className="nav-item">
              <h2 className="hvr-underline-from-center">
                <Link to="/" className="link" style={{ color: "black" }}>
                  HOME
                </Link>
              </h2>
            </div>
            <div className="nav-item">
              <h2
                className="hvr-underline-from-center"
                style={{ cursor: "pointer" }}
              >
                <Link
                  to="/prodotti"
                  className="link"
                  style={{ color: "black" }}
                >
                  Prodotti
                </Link>
              </h2>
            </div>
            <div className="nav-item">
              <h2 className="hvr-underline-from-center">
                <Link
                  to="/agevolazioni"
                  className="link"
                  style={{ color: "black" }}
                >
                  Agevolazioni
                </Link>
              </h2>
            </div>
            <div className="nav-item">
              <h2 className="hvr-underline-from-center">
                <Link
                  to="/installazioni"
                  className="link"
                  style={{ color: "black" }}
                >
                  Installazioni
                </Link>
              </h2>
            </div>
            <div className="nav-item">
              <h2 className="hvr-underline-from-center">
                <Link
                  to="/contatti"
                  className="link"
                  style={{ color: "black" }}
                >
                  Contatti
                </Link>
              </h2>
            </div>
            <Button style={{ padding: "0" }}>
              <Link to="/" className="link">
                <img
                  src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/logo_crystal.jpg"
                  alt="logo"
                  style={{ width: "100px", height: "80px" }}
                />
              </Link>
            </Button>
          </nav>

          <div className="pedane-home">
            <Grid container style={{ padding: "4rem 2rem" }}>
              <Grid item xs={12}>
                <div className="pavimenti-container">
                  <h1 className="product-title" style={{ color: "black" }}>
                    Pavimenti
                  </h1>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }} className="center">
                      <p className="pavimenti-paragraph">
                        Versatilità, tecnologia ed estetica, questi i punti
                        forti delle chiusure per balconi e di tutti i
                        complementi CrystalTende, elementi importanti per
                        integrare e rendere funzionale lo spazio outdoor.
                      </p>
                    </div>
                    <div
                      style={{ width: "50%", padding: "0 2rem" }}
                      className="center"
                    >
                      <p className="pavimenti-paragraph">
                        Se la scelta della pavimentazione per interni si basa
                        maggiormente su canoni estetici, quella di una pedana da
                        giardino o cortile, invece, deve essere guidata
                        soprattutto da criteri tecnici e funzionali. Infatti,
                        tutto ciò che fa parte del vostro outdoor, è sottoposto
                        all’azione di agenti atmosferici, graffi o abrasioni che
                        possono limitarne le potenzialità o accelerarne l’usura,
                        se non si fa un’attenta valutazione.
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="pedane-bottom">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Splide options={{ autoplay: "true" }}>
                  {images.map((image) => {
                    return (
                      <SplideSlide className="center" key={image.label}>
                        <img
                          src={image.imgPath}
                          alt={image.label}
                          className="pavimenti-splide"
                        />
                      </SplideSlide>
                    );
                  })}
                </Splide>
              </Grid>
              <Grid item xs={6}>
                <h1 className="product-title">Pedane modulari</h1>
                <p style={{ fontSize: "2rem" }}>
                  Si tratta di una pedana modulare realizzata in doghe di legno
                  Cumarù e composta da listoni pre-assemblati di larghezza 10 cm
                  e spessore 2 cm.
                </p>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
