import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function Navbar() {
  return (
    <nav className="nav-container">
      <div className="nav-item">
        <h2 className="hvr-underline-from-center">
          <Link to="/" className="link" style={{ color: "black" }}>
            HOME
          </Link>
        </h2>
      </div>
      <div className="nav-item">
        <h2 className="hvr-underline-from-center" style={{ cursor: "pointer" }}>
          <Link to="/prodotti" className="link" style={{ color: "black" }}>
            Prodotti
          </Link>
        </h2>
      </div>
      <div className="nav-item">
        <h2 className="hvr-underline-from-center">
          <Link to="/agevolazioni" className="link" style={{ color: "black" }}>
            Agevolazioni
          </Link>
        </h2>
      </div>
      <div className="nav-item">
        <h2 className="hvr-underline-from-center">
          <Link to="/installazioni" className="link" style={{ color: "black" }}>
            Installazioni
          </Link>
        </h2>
      </div>
      <div className="nav-item">
        <h2 className="hvr-underline-from-center">
          <Link to="/contatti" className="link" style={{ color: "black" }}>
            Contatti
          </Link>
        </h2>
      </div>
      <Button style={{ padding: "0" }}>
        <Link to="/" className="link">
          <img
            src="https://crystal-tende-bucket.s3.eu-central-1.amazonaws.com/logo_crystal.jpg"
            alt="logo"
            style={{ width: "100px", height: "80px" }}
          />
        </Link>
      </Button>
    </nav>
  );
}
