import React, { useState, useEffect } from "react";
import aws from "aws-sdk";
import data from "../../data";
import ProdottoDetailDialog from "./ProdottoDetailDialog";
import ProdottoItem from "./ProdottoItem";
import { useMediaQuery } from "react-responsive";
import NavbarMobile from "../NavbarMobile";
import Navbar from "../Navbar";

const ACCESSKEYID = "AKIA6O3BXFU5B2H3SBPC";
const SECRETKEY =  "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";
const BUCKETNAME = "crystal-tende-bucket";

aws.config.update({ region: "eu-central-1" });
const s3 = new aws.S3({ accessKeyId: ACCESSKEYID, secretAccessKey: SECRETKEY });

var bucketFrangisolef20 = {
  Bucket: BUCKETNAME,
  Prefix: "frangisole/f20",
};

var bucketFrangisolef30 = {
  Bucket: BUCKETNAME,
  Prefix: "frangisole/f30",
};

var bucketFrangisoleGrigliato = {
  Bucket: BUCKETNAME,
  Prefix: "frangisole/grigliato",
};

var bucketFrangisolePedane = {
  Bucket: BUCKETNAME,
  Prefix: "frangisole/pedane",
};

export default function Frangisole() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [frangisolef20, setFrangisolef20] = useState([]);
  const [frangisolef30, setFrangisolef30] = useState([]);
  const [frangisoleGrigliato, setFrangisoleGrigliato] = useState([]);
  const [frangisolePedane, setFrangisolePedane] = useState([]);

  const [frangisolef20Item, setFrangisolef20Item] = useState("");
  const [frangisolef30Item, setFrangisolef30Item] = useState("");
  const [frangisoleGrigliatoItem, setFrangisoleGrigliatoItem] = useState("");
  const [frangisolePedaneItem, setFrangisolePedaneItem] = useState("");

  const [openDetailF20, setOpenDetailF20] = useState(false);
  const [openDetailF30, setOpenDetailF30] = useState(false);
  const [openDetailGrigliato, setOpenDetailGrigliato] = useState(false);
  const [openDetailPedane, setOpenDetailPedane] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    s3.listObjects(bucketFrangisolef20, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setFrangisolef20(keyArray);
      }
    });

    s3.listObjects(bucketFrangisolef30, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setFrangisolef30(keyArray);
      }
    });

    s3.listObjects(bucketFrangisoleGrigliato, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setFrangisoleGrigliato(keyArray);
      }
    });

    s3.listObjects(bucketFrangisolePedane, function (err, data) {
      if (err) {
        alert("Qualcosa è andato storto, riprova!");
      } else {
        var keyArray = [];
        data.Contents.forEach((element) => {
          keyArray.push(element.Key);
        });
        keyArray.splice(0, 1);
        setFrangisolePedane(keyArray);
      }
    });
  }, []);

  const handleFrangisolef20 = (name, img) => {
    data.frangisole[0].f20.forEach((element) => {
      if (element.nome === name) {
        setFrangisolef20Item({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailF20(true);
  };

  const handleFrangisolef30 = (name, img) => {
    data.frangisole[0].f30.forEach((element) => {
      if (element.nome === name) {
        setFrangisolef30Item({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailF30(true);
  };

  const handleFrangisoleGrigliato = (name, img) => {
    data.frangisole[0].grigliato.forEach((element) => {
      if (element.nome === name) {
        setFrangisoleGrigliatoItem({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailGrigliato(true);
  };

  const handleFrangisolePedane = (name, img) => {
    data.frangisole[0].pedane.forEach((element) => {
      if (element.nome === name) {
        setFrangisolePedaneItem({
          img,
          name: element.nome.replaceAll("_", " "),
          description: element.descrizione,
        });
      }
    });
    setOpenDetailPedane(true);
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "1rem 1rem 5rem 1rem",
      }}
    >
      {isTabletOrMobile ? <NavbarMobile /> : <Navbar />}
      <div className="center" style={{ width: "100%", marginTop: "2rem" }}>
        <h1 className="product-title">Frangisole</h1>
      </div>
      <hr
        className={
          isTabletOrMobile ? "product-title-hr-mobile" : "product-title-hr"
        }
      ></hr>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>F20</h2>
      </div>
      <ProdottoItem
        prodotto={frangisolef20}
        handleDetail={handleFrangisolef20}
        vertical={true}
      />
      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>F30</h2>
      </div>
      <ProdottoItem
        prodotto={frangisolef30}
        handleDetail={handleFrangisolef30}
        vertical={true}
      />
      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Grigliato</h2>
      </div>
      <ProdottoItem
        prodotto={frangisoleGrigliato}
        handleDetail={handleFrangisoleGrigliato}
        vertical={true}
      />
      <div style={{ margin: "3rem 0" }}>
        <hr style={{ width: "90%" }}></hr>
      </div>
      <div
        className={isTabletOrMobile ? "item-subtitle-mobile" : "item-subtitle"}
      >
        <h2>Pedane</h2>
      </div>
      <ProdottoItem
        prodotto={frangisolePedane}
        handleDetail={handleFrangisolePedane}
      />

      <ProdottoDetailDialog
        open={openDetailF20}
        setOpen={setOpenDetailF20}
        prodotto={frangisolef20Item}
        vertical={true}
      />

      <ProdottoDetailDialog
        open={openDetailF30}
        setOpen={setOpenDetailF30}
        prodotto={frangisolef30Item}
        vertical={true}
      />

      <ProdottoDetailDialog
        open={openDetailGrigliato}
        setOpen={setOpenDetailGrigliato}
        prodotto={frangisoleGrigliatoItem}
        vertical={true}
      />

      <ProdottoDetailDialog
        open={openDetailPedane}
        setOpen={setOpenDetailPedane}
        prodotto={frangisolePedaneItem}
      />
    </div>
  );
}
